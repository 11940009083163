function _EMOTION_STRINGIFIED_CSS_ERROR__() { return "You have tried to stringify object returned from `css` function. It isn't supposed to be used directly (e.g. as value of the `className` prop), but rather handed to emotion so it can handle it (e.g. as value of `css` prop)."; }
import { signIn } from "next-auth/client";
import { useEffect, useMemo, useState } from "react";
import { captureException, captureMessage } from "@sentry/nextjs";
import { useDebouncedCallback } from "use-debounce";
import type { JsonObject } from "type-fest";
import { toast } from "react-toastify";
import { Organization } from "@a101/api/graphql/generated/ssr";
import Checkbox from "@a101/components/design-system/components/forms/checkbox";
import TextInput from "@a101/components/design-system/components/forms/text-input";
import Button from "@a101/components/design-system/components/general/button";
import Icon, { socialIconMap } from "@a101/components/design-system/components/general/icon";
import { Precedence, UserOrgStatus, UserOrganizationFragment, useCreateInviteMutation, useCreateUserMutation, useCreateUserOrganizationMutation, useInvitedByEmailQuery, useUpdateUserMutation, useUserByEmailQuery } from "@a101/api/graphql/generated/client";
import Dropdown from "@a101/components/design-system/components/forms/dropdown";
import AnimatedEllipsis from "@a101/components/design-system/components/general/animated-ellipsis";
import { Role, UserStatus } from "@a101/api/graphql/generated/server";
import { getServerSideProps as getSSRProps } from "../../components/platform/login/login-ssr";
import LoginWrapper from "../../components/platform/login/login-wrapper";
export const getServerSideProps = getSSRProps;
var _ref = process.env.NODE_ENV === "production" ? {
  name: "1guom3y",
  styles: "position:relative;margin-top:2.5rem;margin-bottom:2.5rem;display:flex;width:100%;align-items:flex-start;justify-content:center;padding-left:2.5rem;padding-right:2.5rem;font-family:Qanelas;color:var(--color-primary);@media (min-width: 1024px){margin-top:5rem;margin-bottom:5rem;}"
} : {
  name: "rlf4vg-SignIn",
  styles: "position:relative;margin-top:2.5rem;margin-bottom:2.5rem;display:flex;width:100%;align-items:flex-start;justify-content:center;padding-left:2.5rem;padding-right:2.5rem;font-family:Qanelas;color:var(--color-primary);@media (min-width: 1024px){margin-top:5rem;margin-bottom:5rem;};label:SignIn;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref2 = process.env.NODE_ENV === "production" ? {
  name: "58inu",
  styles: "display:flex;max-width:28rem;flex-direction:column;gap:1.25rem"
} : {
  name: "85c54a-SignIn",
  styles: "display:flex;max-width:28rem;flex-direction:column;gap:1.25rem;label:SignIn;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref3 = process.env.NODE_ENV === "production" ? {
  name: "9kwmmn",
  styles: "margin-bottom:1.25rem"
} : {
  name: "rniq8a-SignIn",
  styles: "margin-bottom:1.25rem;label:SignIn;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref4 = process.env.NODE_ENV === "production" ? {
  name: "1p8oixc",
  styles: "font-size:1.875rem;font-weight:800;text-transform:uppercase;@media (min-width: 1024px){font-size:2.25rem;}"
} : {
  name: "1y8f2qz-SignIn",
  styles: "font-size:1.875rem;font-weight:800;text-transform:uppercase;@media (min-width: 1024px){font-size:2.25rem;};label:SignIn;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref5 = process.env.NODE_ENV === "production" ? {
  name: "1io9dqi",
  styles: "font-size:1rem;font-weight:500;@media (min-width: 1024px){font-size:1.25rem;}"
} : {
  name: "9oepl8-SignIn",
  styles: "font-size:1rem;font-weight:500;@media (min-width: 1024px){font-size:1.25rem;};label:SignIn;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref6 = process.env.NODE_ENV === "production" ? {
  name: "dhj7ib",
  styles: "display:flex;flex-direction:column;gap:1.25rem"
} : {
  name: "1698n7e-SignIn",
  styles: "display:flex;flex-direction:column;gap:1.25rem;label:SignIn;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref7 = process.env.NODE_ENV === "production" ? {
  name: "bjn8wh",
  styles: "position:relative"
} : {
  name: "12wa724-SignIn",
  styles: "position:relative;label:SignIn;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref8 = process.env.NODE_ENV === "production" ? {
  name: "182576t",
  styles: "position:absolute;right:0.5rem;text-align:right;font-size:1.25rem;top:31px"
} : {
  name: "1dlhu26-SignIn",
  styles: "position:absolute;right:0.5rem;text-align:right;font-size:1.25rem;top:31px;label:SignIn;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref9 = process.env.NODE_ENV === "production" ? {
  name: "1stxi6u",
  styles: "margin-top:0.625rem;border-width:1px;border-color:var(--color-primary);padding:1.25rem;font-weight:500;background-color:var(--palette-tertiary-fuchsia)"
} : {
  name: "r6iif4-SignIn",
  styles: "margin-top:0.625rem;border-width:1px;border-color:var(--color-primary);padding:1.25rem;font-weight:500;background-color:var(--palette-tertiary-fuchsia);label:SignIn;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref10 = process.env.NODE_ENV === "production" ? {
  name: "p5tmle",
  styles: "display:flex;flex-direction:column;gap:0.5rem"
} : {
  name: "ntt1ss-SignIn",
  styles: "display:flex;flex-direction:column;gap:0.5rem;label:SignIn;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref11 = process.env.NODE_ENV === "production" ? {
  name: "18mksrm",
  styles: "display:flex;gap:0.5rem;font-size:0.6875rem"
} : {
  name: "rsnggz-SignIn",
  styles: "display:flex;gap:0.5rem;font-size:0.6875rem;label:SignIn;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref12 = process.env.NODE_ENV === "production" ? {
  name: "185o5ba",
  styles: "text-decoration-line:underline"
} : {
  name: "7xz6ex-SignIn",
  styles: "text-decoration-line:underline;label:SignIn;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
var _ref13 = process.env.NODE_ENV === "production" ? {
  name: "6jc88l",
  styles: "text-align:center;text-transform:uppercase"
} : {
  name: "8c7d0h-SignIn",
  styles: "text-align:center;text-transform:uppercase;label:SignIn;",
  toString: _EMOTION_STRINGIFIED_CSS_ERROR__
};
const SignIn = ({
  providers,
  organization
}: {
  providers: JsonObject;
  organization: Organization;
}) => {
  //	! ----------> STATE <----------
  // user email address
  const [email, setEmail] = useState("");

  // user name
  const [name, setName] = useState("");

  // user date of birth
  const [dob, setDOB] = useState<Date>();

  // should the submit buttons show their loading state?
  const [isLoading, setIsLoading] = useState(false);

  //
  const [selectedOrgId, setSelectedOrgId] = useState(organization?.id ?? "");

  // setEmail, but debounced so it doesn't call until the user has finished typing
  const debouncedEmail = useDebouncedCallback(value => {
    setEmail(value);
  }, 1000);
  const [checkbox, setCheckbox] = useState(false);

  //	! ----------> QUERIES <----------

  // check if the user exists
  const {
    data: user,
    isLoading: userIsLoading,
    isFetched: userIsFetched
  } = useUserByEmailQuery({
    email
  }, {
    enabled: !!email && email.trim() !== ``
  });

  // check if invites exist for the user
  const {
    data: invites,
    isLoading: invitesLoading,
    isFetched: invitesFetched
  } = useInvitedByEmailQuery({
    email
  }, {
    enabled: !!email && email.trim() !== ``
  });

  //	! ----------> MUTATIONS <----------

  // mutation to update the user
  const userMutation = useUpdateUserMutation();

  // mutation to create an invite
  const createInviteMutation = useCreateInviteMutation();

  // mutation to create an user
  const createUserMutation = useCreateUserMutation();

  // mutation to create an user org relationship
  const createUserOrgMutation = useCreateUserOrganizationMutation();

  //	! ----------> QUERY-DEPENDENT STATE <----------

  const orgList = useMemo(() => {
    const arr: {
      id: string;
      name: string;
    }[] = [];
    // set the list to be the user's organization relations
    if (user && user?.userByEmail?.userOrganization) {
      user.userByEmail.userOrganization.forEach(userOrg => {
        if (userOrg && userOrg.orgId && userOrg.organization?.name) {
          arr.push({
            id: userOrg.orgId,
            name: userOrg.organization.name
          });
        }
      });
    }

    // if the user exists, set the list to have the user's inherit organization relation
    if (user?.userByEmail && user?.userByEmail?.orgId && user?.userByEmail?.organization && !user?.userByEmail?.userOrganization) {
      arr.push({
        id: user.userByEmail.orgId,
        name: user.userByEmail.organization.name
      });
    }

    // if the organization exists on the invite, set the org dropdown to have that org
    if (invites && invites?.invitedByEmail && invites?.invitedByEmail.length > 0) {
      invites.invitedByEmail.forEach(invite => {
        if (invite && invite.orgId && invite?.organization?.name) {
          arr.push({
            id: invite.orgId,
            name: invite.organization.name
          });
        }
      });
    }
    return arr;
  }, [invites, user]);
  const userExists = userIsFetched && !!user?.userByEmail;
  const userDoesNotExist = userIsFetched && !user?.userByEmail;
  const inviteExists = invitesFetched && invites?.invitedByEmail && invites?.invitedByEmail.length > 0;
  const inviteDoesNotExist = invitesFetched && invites?.invitedByEmail && invites?.invitedByEmail.length === 0;
  const notAllowedInOrgDomain = email && Array.isArray(organization?.domains) && organization?.domains?.length > 0 && !organization.domains.includes(email.split(`@`)[1]);
  const newStatusMessage = !organization ? `You must login using your organization's unique login page. Check for an email from your organization, or contact them for more information.` : notAllowedInOrgDomain ? `The email address provided is not on the allowed list of domains for this organization. Please provide an email address with one of the following domains: ${(organization?.domains ?? []).join(`, `)}` : undefined;
  const askForName = inviteExists && invites?.invitedByEmail && invites?.invitedByEmail.some(invite => !invite?.name) || userExists && user.userByEmail && !user.userByEmail.name || inviteDoesNotExist && userDoesNotExist && !newStatusMessage;
  const askForDOB = inviteExists && invites?.invitedByEmail && invites?.invitedByEmail.some(invite => !invite?.dob) || userExists && user.userByEmail && !user.userByEmail.dob || inviteDoesNotExist && userDoesNotExist && !newStatusMessage;
  const canLogin = (userExists || inviteExists && (!askForName || name && name.trim() !== ``) && (!askForDOB || dob) || organization && name && dob) && (!!selectedOrgId || !!organization);

  //	! ----------> EFFECTS <----------

  useEffect(() => {
    if (userExists && !organization) {
      if (user.userByEmail?.orgId && user?.userByEmail?.userOrganization && user.userByEmail.userOrganization?.find(org => org?.orgId === user.userByEmail?.orgId)) {
        setSelectedOrgId(user.userByEmail.orgId);
        return;
      }
      if (user?.userByEmail?.userOrganization && user.userByEmail.userOrganization[0]?.orgId) {
        setSelectedOrgId(user.userByEmail.userOrganization[0]?.orgId);
        return;
      }
      if (!user.userByEmail?.orgId) {
        console.error("User does not have organization relationship");
        captureMessage("User does not have organization relationship", {
          user: {
            id: user.userByEmail?.id ?? "",
            email: user.userByEmail?.email
          }
        });
        return;
      }
      setSelectedOrgId(user.userByEmail?.orgId);
      return;
    }
    if (inviteExists && !organization && invites.invitedByEmail && invites.invitedByEmail[0]?.orgId) {
      setSelectedOrgId(invites.invitedByEmail[0].orgId);
    }
  }, [invites?.invitedByEmail, inviteExists, organization, user?.userByEmail?.orgId, user?.userByEmail?.userOrganization, userExists, user?.userByEmail?.id, user?.userByEmail?.email]);
  useEffect(() => {
    if (userExists) {
      setCheckbox(true);
    }
  }, [userExists]);

  //	! ----------> CALLBACKS <----------

  /**
   * ! Creates or updates the user based on if their user exists,
   * ! if their an invited user, or if the user is currently self-registering.
   *
   * ? We do this to handle the many-to-many relationship between users
   * ? and organizations, whatever org attributes that are on the user entity
   * ? is the organization the user is logged/logging in with.
   */
  const createOrUpdateUser = async () => {
    // Add extra organization relationship to existing user
    if (userExists && inviteExists) {
      const searchOrgId = organization?.id ?? selectedOrgId;
      const inviteForOrg = invites.invitedByEmail?.find(invite => invite?.orgId === searchOrgId);

      // If we find an invite, we follow this flow, otherwise we do the normal `userExists` flow
      if (inviteForOrg) {
        const orgRelationshipAlreadyExists = user?.userByEmail?.userOrganization ? user?.userByEmail?.userOrganization.some(userOrg => userOrg?.orgId === searchOrgId) : false;
        if (!user?.userByEmail?.id) {
          throw new Error("Unable to fetch id for user when user and invite exists");
        }
        if (!orgRelationshipAlreadyExists) {
          await createUserOrgMutation.mutateAsync({
            data: {
              userId: user.userByEmail?.id,
              orgId: searchOrgId,
              groups: inviteForOrg.groups ?? [],
              precedence: user?.userByEmail?.userOrganization && user?.userByEmail?.userOrganization.length > 0 ? Precedence.Secondary : Precedence.Primary,
              disciplined: inviteForOrg.disciplined,
              exempt: inviteForOrg.exempt,
              dueDate: inviteForOrg.dueDate,
              schoolYear: inviteForOrg.schoolYear,
              status: UserOrgStatus.OrgJoined
            }
          });
        }
        await userMutation.mutateAsync({
          id: user.userByEmail?.id,
          orgId: searchOrgId,
          data: {
            name: user?.userByEmail?.name,
            dob: user?.userByEmail?.dob,
            schoolYear: inviteForOrg.schoolYear,
            groups: inviteForOrg.groups,
            dueDate: inviteForOrg.dueDate,
            disciplined: inviteForOrg.disciplined,
            exempt: inviteForOrg.exempt,
            orgId: inviteForOrg.orgId,
            deletedAt: null
          }
        });
        return;
      }
    }
    if (userExists) {
      let selectedOrg: UserOrganizationFragment | null | undefined;
      selectedOrg = user?.userByEmail?.userOrganization && organization ? user?.userByEmail?.userOrganization.find(x => x?.orgId === organization?.id) : user?.userByEmail?.userOrganization && selectedOrgId ? user?.userByEmail?.userOrganization.find(x => x?.orgId === selectedOrgId) : null;
      if (!user.userByEmail) {
        throw new Error("User is not defined when updating user with M2M organization relationship");
      }
      if (!selectedOrg) {
        const createdUserOrgRelationship = await createUserOrgMutation.mutateAsync({
          data: {
            userId: user.userByEmail.id,
            orgId: user.userByEmail.orgId ?? organization.id,
            groups: user.userByEmail.groups,
            precedence: user?.userByEmail?.userOrganization && user?.userByEmail?.userOrganization.length > 0 ? Precedence.Secondary : Precedence.Primary,
            disciplined: user.userByEmail.disciplined,
            dueDate: user.userByEmail.dueDate,
            schoolYear: user.userByEmail.schoolYear,
            status: UserOrgStatus.OrgJoined
          }
        });
        selectedOrg = createdUserOrgRelationship.userOrganization;
      }
      if (!selectedOrg) {
        throw new Error("Selected Org is not defined when updating user with M2M organization relationship");
      }
      await userMutation.mutateAsync({
        id: user.userByEmail.id,
        orgId: selectedOrg.id,
        data: {
          name: user?.userByEmail?.name,
          dob: user?.userByEmail?.dob,
          schoolYear: selectedOrg.schoolYear,
          groups: selectedOrg.groups,
          dueDate: selectedOrg.dueDate,
          disciplined: selectedOrg.disciplined,
          exempt: selectedOrg.exempt,
          orgId: selectedOrg.orgId,
          deletedAt: selectedOrg.deletedAt
        }
      });
      return;
    }
    if (inviteExists && userDoesNotExist) {
      const searchOrgId = organization?.id ?? selectedOrgId;
      if (!searchOrgId) {
        throw new Error("Org Id is not defined when accepting invite");
      }
      const inviteForOrg = invites.invitedByEmail?.find(invite => invite?.orgId === searchOrgId);
      if (!inviteForOrg) {
        throw new Error("Unable to find invite for selected org when attempting to accept");
      }
      const newInvitedUser = await createUserMutation.mutateAsync({
        data: {
          name: inviteForOrg.name ?? name,
          email: inviteForOrg.email,
          role: inviteForOrg.role ?? Role.Student,
          orgId: inviteForOrg.orgId ?? organization.id,
          dob: inviteForOrg.dob ?? dob,
          groups: inviteForOrg.groups,
          disciplined: inviteForOrg.disciplined ?? false,
          exempt: inviteForOrg.exempt ?? false,
          dueDate: inviteForOrg.dueDate,
          schoolYear: inviteForOrg.schoolYear ?? new Date().getFullYear(),
          status: inviteForOrg.role === Role.Student ? UserStatus.StudentInvited : UserStatus.AdminInvited,
          // will be set to active upon successfull sign-in,
          acquisition: `invited`
        }
      });
      if (!newInvitedUser.createUser) {
        throw new Error("Unable to create new invited user when invite exists");
      }
      if (!newInvitedUser.createUser.orgId) {
        throw new Error("OrgId is not defined when creating new invited user when invite exists");
      }
      await createUserOrgMutation.mutateAsync({
        data: {
          userId: newInvitedUser.createUser.id,
          orgId: newInvitedUser.createUser.orgId,
          groups: newInvitedUser.createUser.groups,
          precedence: Precedence.Primary,
          disciplined: newInvitedUser.createUser.disciplined,
          dueDate: newInvitedUser.createUser.dueDate,
          schoolYear: newInvitedUser.createUser.schoolYear,
          status: UserOrgStatus.OrgJoined
        }
      });
      return;
    }
    if (userDoesNotExist && inviteDoesNotExist) {
      // create invite just in case for reference
      const createInviteWithNoEmail = await createInviteMutation.mutateAsync({
        user: {
          name,
          dob,
          email,
          orgId: organization.id,
          role: Role.Student
        },
        sendEmail: false
      });
      if (!createInviteWithNoEmail?.createInvite) {
        throw new Error("Unable to create new invite when neither an invite or user exists");
      }

      // if the user and an invite does not exist, it is inferred that the user is self-registering
      const newSelfRegisteredStudent = await createUserMutation.mutateAsync({
        data: {
          name,
          email,
          role: Role.Student,
          orgId: organization.id,
          dob,
          groups: createInviteWithNoEmail?.createInvite.groups,
          disciplined: createInviteWithNoEmail?.createInvite.disciplined ?? false,
          dueDate: createInviteWithNoEmail?.createInvite.dueDate,
          exempt: createInviteWithNoEmail?.createInvite.exempt ?? false,
          schoolYear: createInviteWithNoEmail?.createInvite.schoolYear ?? new Date().getFullYear(),
          status: UserStatus?.StudentInvited,
          // will be set to active upon successfull sign-in
          acquisition: `self-registered`
        }
      });
      if (!newSelfRegisteredStudent?.createUser) {
        throw new Error("Unable to create new user when neither an invite or user exists");
      }
      if (!newSelfRegisteredStudent?.createUser.orgId) {
        throw new Error("OrgId is not defined when creating new invited user when neither an invite or user exists");
      }
      await createUserOrgMutation.mutateAsync({
        data: {
          userId: newSelfRegisteredStudent.createUser.id,
          orgId: newSelfRegisteredStudent.createUser.orgId,
          groups: newSelfRegisteredStudent.createUser.groups,
          precedence: Precedence.Primary,
          disciplined: newSelfRegisteredStudent.createUser.disciplined,
          dueDate: newSelfRegisteredStudent.createUser.dueDate,
          schoolYear: newSelfRegisteredStudent.createUser.schoolYear,
          status: UserOrgStatus.OrgJoined
        }
      });
    }
  };
  const updateAndSignIn = async (type: string) => {
    setIsLoading(true);
    try {
      await createOrUpdateUser();

      /** @see {@link [...nextauth].ts} for sign-in method and side effects */
      signIn(type, {
        email,
        callbackUrl: `/`
      });
    } catch (error) {
      if (error instanceof Error) {
        captureException(error, {
          tags: {
            email,
            selectedOrgId: selectedOrgId || "Not Defined",
            pageOrganization: organization?.id || "Not Defined",
            userExists,
            inviteExists,
            notAllowedInOrgDomain,
            canLogin
          }
        });
        toast.error(`Failed to sign-in, please try again later`, {
          containerId: `action`
        });
        console.error(`Sign-in Error:`, error.message);
        setIsLoading(false);
      }
    }
  };
  return <LoginWrapper organization={organization}>
			<div css={_ref}>
				<div css={_ref2}>
					{/* ========= HEADLINE ========= */}
					<div css={_ref3}>
						<h1 css={_ref4}>Welcome!</h1>
						<p css={_ref5}>
							Please enter your email address to continue.
						</p>
					</div>
					{/* ========= EMAIL ========= */}

					<div css={_ref6}>
						<div css={_ref7}>
							<TextInput label="Email" type="email" required instructions={userIsLoading || invitesLoading ? `Checking for your account...` : ``} onChange={e => debouncedEmail(e.target.value)} />

							<div css={_ref8}>
								{(userIsLoading || invitesLoading) && <AnimatedEllipsis />}
								{(userExists && (user?.userByEmail?.orgId || organization) || inviteExists && (orgList.length > 0 || organization)) && <Icon name="Check" color="var(--palette-tertiary-green)" strokeWidth="3px" />}
							</div>
						</div>

						{(userDoesNotExist || userExists && !user?.userByEmail?.orgId && !organization) && (inviteDoesNotExist || inviteExists && orgList.length === 0 && !organization) && newStatusMessage && <div css={_ref9}>
									{newStatusMessage}
								</div>}

						{askForName && <TextInput label="Name" onChange={e => setName(e.target.value)} />}

						{askForDOB && <TextInput label="Date of Birth" type="date" onChange={e => setDOB(new Date(e.target.value))} />}
					</div>
					{!organization && canLogin && <div css={_ref10}>
							<Dropdown label="Organization" options={orgList?.map(org => ({
            value: org?.id,
            label: org?.name
          }))} defaultValue={{
            value: selectedOrgId,
            label: orgList?.find(org => org.id === selectedOrgId)?.name
          }} onChange={data => {
            if (data) {
              setSelectedOrgId(data?.value);
            }
          }} />
						</div>}
					{/* BUTTONS */}
					{canLogin && <>
							{/* ========= NOTE ========= */}
							<div css={_ref11}>
								<Checkbox status={checkbox ? `CHECKED` : `UNCHECKED`} onCheck={() => setCheckbox(!checkbox)} />
								<p>
									I hereby acknowledge that I have read and understood the{` `}
									<a href="/terms" target="_blank" css={_ref12}>
										Terms of Service
									</a>
									{` `}
									and agree to be bound by them.
								</p>
							</div>

							<Button type="submit" label="Register / Sign in" stretch isLoading={isLoading} disabled={!checkbox} onClick={() => updateAndSignIn(`email`)} />

							{/* ========= ADDITIONAL PROVIDERS ========= */}
							<div css={_ref13}>Or</div>
							<Button type="button" label={"Sign in with Google"} stretch disabled={!checkbox} color="transparent" icon={socialIconMap["google"]} isLoading={isLoading} onClick={() => updateAndSignIn("google")} />
							<Button type="button" label={"Sign in with Microsoft"} stretch disabled={!checkbox} color="transparent" icon={socialIconMap["azure-ad-b2c"]} isLoading={isLoading} onClick={() => updateAndSignIn("azure-ad-b2c")} />
						</>}
				</div>
			</div>
		</LoginWrapper>;
};
export default SignIn;