/* eslint-disable @typescript-eslint/ban-types */
import { GraphQLResolveInfo, GraphQLScalarType, GraphQLScalarTypeConfig } from 'graphql';
import { Context } from '../context';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends {
  [key: string]: unknown;
}> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends {
  [key: string]: unknown;
}, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
export type RequireFields<T, K extends keyof T> = Omit<T, K> & { [P in K]-?: NonNullable<T[P]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: {
    input: string;
    output: string;
  };
  String: {
    input: string;
    output: string;
  };
  Boolean: {
    input: boolean;
    output: boolean;
  };
  Int: {
    input: number;
    output: number;
  };
  Float: {
    input: number;
    output: number;
  };
  DateTime: {
    input: any;
    output: any;
  };
  JSON: {
    input: any;
    output: any;
  };
};
export enum AccentColor {
  Blue = 'BLUE',
  Gold = 'GOLD',
  Gray = 'GRAY',
  Green = 'GREEN',
  Orange = 'ORANGE',
  Pink = 'PINK',
  Salmon = 'SALMON',
  Turquoise = 'TURQUOISE',
  Violet = 'VIOLET',
  Yellow = 'YELLOW',
}
export type Account = {
  __typename?: 'Account';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  providerAccountId: Scalars['String']['output'];
  providerId: Scalars['String']['output'];
};
export type AgeDataFiliter = {
  dobAge?: InputMaybe<Scalars['String']['input']>;
  dobCompare?: InputMaybe<Dobcompare>;
  gender?: InputMaybe<Gender>;
};
export enum Category {
  Hbcu = 'HBCU',
  HispanicServing = 'HISPANIC_SERVING',
  NaiaDivisionI = 'NAIA_DIVISION_I',
  NaiaDivisionIi = 'NAIA_DIVISION_II',
  NcaaDivisionI = 'NCAA_DIVISION_I',
  NcaaDivisionIi = 'NCAA_DIVISION_II',
  NcaaDivisionIii = 'NCAA_DIVISION_III',
  TribalCollege = 'TRIBAL_COLLEGE',
}
export type CreateOrganizationInput = {
  accentColor?: InputMaybe<AccentColor>;
  address?: InputMaybe<Array<Scalars['String']['input']>>;
  administrativeArea?: InputMaybe<Scalars['String']['input']>;
  alternateContact?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<Category>>;
  contactPerson?: InputMaybe<Scalars['JSON']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  helpDesk?: InputMaybe<Scalars['String']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Location>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  notes?: InputMaybe<Scalars['String']['input']>;
  permission?: InputMaybe<PermissionTier>;
  portalText?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  primaryColor?: InputMaybe<PrimaryColor>;
  size?: InputMaybe<Size>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OrgStatus>;
  types?: InputMaybe<Array<Type>>;
  website?: InputMaybe<Scalars['String']['input']>;
};
export type CreateUserInput = {
  acquisition?: InputMaybe<Scalars['String']['input']>;
  disciplined?: InputMaybe<Scalars['Boolean']['input']>;
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  exempt?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  name: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  role: Role;
  roleElevations?: InputMaybe<Array<RoleElevation>>;
  schoolYear?: InputMaybe<Scalars['Int']['input']>;
  settings?: InputMaybe<SettingsInput>;
  sex?: InputMaybe<Sex>;
  status: UserStatus;
};
export enum Dobcompare {
  Above = 'ABOVE',
  Under = 'UNDER',
}
export type DashboardData = {
  __typename?: 'DashboardData';
  administratorsInvited: Scalars['Int']['output'];
  administratorsRegistered: Scalars['Int']['output'];
  averageTimeOnPlatform: Scalars['Float']['output'];
  countAllOrganizations: Scalars['Int']['output'];
  studentsComplete: Scalars['Int']['output'];
  studentsExempt: Scalars['Int']['output'];
  studentsInProgress: Scalars['Int']['output'];
  studentsRegistered: Scalars['Int']['output'];
  studentsRostered: Scalars['Int']['output'];
};
export type ExperienceQ07 = {
  __typename?: 'ExperienceQ07';
  LONG_AGO: StudentsExperienceResult;
  MONTH: StudentsExperienceResult;
  NEVER: StudentsExperienceResult;
  YEAR: StudentsExperienceResult;
};
export enum FileType {
  Image = 'IMAGE',
  Pdf = 'PDF',
  Video = 'VIDEO',
  Website = 'WEBSITE',
}
export enum Gender {
  Female = 'FEMALE',
  Male = 'MALE',
  Nonbinary = 'NONBINARY',
}
export type InvitationDetail = {
  __typename?: 'InvitationDetail';
  email?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  permission?: Maybe<StudentPermission>;
};
export type Invite = {
  __typename?: 'Invite';
  disciplined?: Maybe<Scalars['Boolean']['output']>;
  dob?: Maybe<Scalars['DateTime']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  email: Scalars['String']['output'];
  exempt?: Maybe<Scalars['Boolean']['output']>;
  groups?: Maybe<Array<Scalars['String']['output']>>;
  id?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  role?: Maybe<Role>;
  schoolYear?: Maybe<Scalars['Int']['output']>;
};
export type InviteInput = {
  disciplined?: InputMaybe<Scalars['Boolean']['input']>;
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  email: Scalars['String']['input'];
  exempt?: InputMaybe<Scalars['Boolean']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
  role: Role;
  schoolYear?: InputMaybe<Scalars['Int']['input']>;
};
export type InvitedStudentsDetailInput = {
  invitedIds: Array<Scalars['String']['input']>;
  orgId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};
export enum Location {
  LargeCity = 'LARGE_CITY',
  LargeTown = 'LARGE_TOWN',
  RuralCommunity = 'RURAL_COMMUNITY',
  SmallCity = 'SMALL_CITY',
  SmallTown = 'SMALL_TOWN',
  VeryLargeCity = 'VERY_LARGE_CITY',
}
export type Metrics = {
  __typename?: 'Metrics';
  belowAverageCount: Scalars['Int']['output'];
  dashboardData: DashboardData;
  expressConcernCount: Scalars['Int']['output'];
  negativeEffectCount: NegativeEffectCount;
  positiveEffectCount: PositiveEffectCount;
  questionAnswerCountData: QuestionAnswerCountData;
  questionCountData: QuestionCountData;
  riskFactorsCount: Scalars['Int']['output'];
  soberNegativeCount: SoberNegativeCount;
  studentsExperienceData: StudentsExperienceData;
  virtualBarAverageTime: Scalars['Float']['output'];
  virtualBarRevisit: VirtualBarRevisit;
};
export type Mutation = {
  __typename?: 'Mutation';
  _empty?: Maybe<Scalars['String']['output']>;
  archiveNotification?: Maybe<Notification>;
  archiveNotifications?: Maybe<Scalars['Int']['output']>;
  archiveOrganization?: Maybe<Organization>;
  archiveOrganizations: Scalars['Int']['output'];
  completeSectionForUserLessonProgress?: Maybe<UserLessonProgress>;
  createInvite?: Maybe<Invite>;
  createManyInvites?: Maybe<Array<Maybe<Invite>>>;
  createNotification?: Maybe<Notification>;
  createOrganization?: Maybe<Organization>;
  createPing?: Maybe<UserPing>;
  createResource?: Maybe<Resource>;
  createUser?: Maybe<User>;
  createUserLessonProgress?: Maybe<UserLessonProgress>;
  createUserLessonProgresses?: Maybe<Scalars['Int']['output']>;
  createUserOrganization?: Maybe<UserOrganization>;
  createVerificationRequest?: Maybe<VerificationRequest>;
  deleteAllOrganizationsOfUser?: Maybe<Scalars['Int']['output']>;
  deleteInvite?: Maybe<Invite>;
  deleteInvites?: Maybe<Scalars['Int']['output']>;
  deleteNotification?: Maybe<Notification>;
  deleteResource?: Maybe<Resource>;
  deleteUser?: Maybe<User>;
  deleteUserOrganization?: Maybe<UserOrganization>;
  deleteUsers?: Maybe<Scalars['Int']['output']>;
  joinSecondaryOrganization?: Maybe<OrganizationOfStudent>;
  markProgressAsComplete?: Maybe<UserLessonProgress>;
  restoreNotifications?: Maybe<Scalars['Int']['output']>;
  restoreUser?: Maybe<User>;
  restoreUsers?: Maybe<Scalars['Int']['output']>;
  unarchiveOrganization?: Maybe<Organization>;
  unarchiveOrganizations: Scalars['Int']['output'];
  updateInvite?: Maybe<Invite>;
  updateInvites?: Maybe<Scalars['Int']['output']>;
  updateNotification?: Maybe<Notification>;
  updateOrganization?: Maybe<Organization>;
  updateOrganizationGroups?: Maybe<Organization>;
  updateResource?: Maybe<Resource>;
  updateResourceOrder?: Maybe<Organization>;
  updateUser?: Maybe<User>;
  updateUserLessonAnswer: UserLessonAnswer;
  updateUserOrganization?: Maybe<UserOrganization>;
  updateUsers?: Maybe<Scalars['Int']['output']>;
};
export type MutationArchiveNotificationArgs = {
  id: Scalars['Int']['input'];
};
export type MutationArchiveNotificationsArgs = {
  ids: Array<Scalars['Int']['input']>;
};
export type MutationArchiveOrganizationArgs = {
  id: Scalars['String']['input'];
};
export type MutationArchiveOrganizationsArgs = {
  ids: Array<Scalars['String']['input']>;
};
export type MutationCompleteSectionForUserLessonProgressArgs = {
  sectionId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};
export type MutationCreateInviteArgs = {
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
  user: InviteInput;
};
export type MutationCreateManyInvitesArgs = {
  users: Array<InviteInput>;
};
export type MutationCreateNotificationArgs = {
  data: NotificationInfo;
};
export type MutationCreateOrganizationArgs = {
  data: CreateOrganizationInput;
};
export type MutationCreatePingArgs = {
  data: UserPingInfo;
};
export type MutationCreateResourceArgs = {
  data: ResourceInput;
  name: Scalars['String']['input'];
};
export type MutationCreateUserArgs = {
  data: CreateUserInput;
};
export type MutationCreateUserLessonProgressArgs = {
  orgId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};
export type MutationCreateUserLessonProgressesArgs = {
  orgId?: InputMaybe<Scalars['String']['input']>;
  userIds: Array<Scalars['String']['input']>;
};
export type MutationCreateUserOrganizationArgs = {
  data: UserOrganizationInput;
};
export type MutationCreateVerificationRequestArgs = {
  expires: Scalars['DateTime']['input'];
  identifier: Scalars['String']['input'];
  token: Scalars['String']['input'];
};
export type MutationDeleteAllOrganizationsOfUserArgs = {
  userId: Scalars['String']['input'];
};
export type MutationDeleteInviteArgs = {
  email: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationDeleteInvitesArgs = {
  emails: Array<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationDeleteNotificationArgs = {
  id: Scalars['Int']['input'];
};
export type MutationDeleteResourceArgs = {
  id: Scalars['String']['input'];
};
export type MutationDeleteUserArgs = {
  id: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationDeleteUserOrganizationArgs = {
  id: Scalars['String']['input'];
};
export type MutationDeleteUsersArgs = {
  ids: Array<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationJoinSecondaryOrganizationArgs = {
  orgId: Scalars['String']['input'];
  userEmail: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};
export type MutationMarkProgressAsCompleteArgs = {
  userId: Scalars['String']['input'];
};
export type MutationRestoreNotificationsArgs = {
  ids: Array<Scalars['Int']['input']>;
};
export type MutationRestoreUserArgs = {
  id: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationRestoreUsersArgs = {
  ids: Array<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationUnarchiveOrganizationArgs = {
  id: Scalars['String']['input'];
};
export type MutationUnarchiveOrganizationsArgs = {
  ids: Array<Scalars['String']['input']>;
};
export type MutationUpdateInviteArgs = {
  data: UpdateInviteInput;
  email: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationUpdateInvitesArgs = {
  data: UpdateInviteInput;
  emails: Array<Scalars['String']['input']>;
  groupPush?: InputMaybe<Scalars['Boolean']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationUpdateNotificationArgs = {
  data: NotificationInfo;
  id: Scalars['Int']['input'];
};
export type MutationUpdateOrganizationArgs = {
  data: UpdateOrganizationInput;
  id: Scalars['String']['input'];
};
export type MutationUpdateOrganizationGroupsArgs = {
  id: Scalars['String']['input'];
  newGroups: Array<Scalars['String']['input']>;
};
export type MutationUpdateResourceArgs = {
  data: ResourceInput;
  id: Scalars['String']['input'];
};
export type MutationUpdateResourceOrderArgs = {
  id: Scalars['String']['input'];
  orderList: Scalars['JSON']['input'];
};
export type MutationUpdateUserArgs = {
  data: UpdateUserInput;
  id: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type MutationUpdateUserLessonAnswerArgs = {
  answerPayload: Scalars['JSON']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  progressId: Scalars['String']['input'];
  questionId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};
export type MutationUpdateUserOrganizationArgs = {
  data: UserOrganizationInput;
  id: Scalars['String']['input'];
};
export type MutationUpdateUsersArgs = {
  data: UpdateUserInput;
  groupPush?: InputMaybe<Scalars['Boolean']['input']>;
  ids: Array<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
};
export type NegativeEffectCount = {
  __typename?: 'NegativeEffectCount';
  BAD_DECISION: Scalars['Int']['output'];
  BLACKOUT: Scalars['Int']['output'];
  BROWNOUT: Scalars['Int']['output'];
  DEPRESSED: Scalars['Int']['output'];
  DISAPPOINTED: Scalars['Int']['output'];
  DROVE: Scalars['Int']['output'];
  EMBARRASSED: Scalars['Int']['output'];
  GRADES: Scalars['Int']['output'];
  HANGOVER: Scalars['Int']['output'];
  HURT: Scalars['Int']['output'];
  PASSED_OUT: Scalars['Int']['output'];
  POLICE_TROUBLE: Scalars['Int']['output'];
  PROPERTY: Scalars['Int']['output'];
  SCHOOL_TROUBLE: Scalars['Int']['output'];
  SEX: Scalars['Int']['output'];
  SOCIAL_MEDIA: Scalars['Int']['output'];
  SUICIDE: Scalars['Int']['output'];
};
export type Notification = {
  __typename?: 'Notification';
  audience: Array<NotificationAudience>;
  body?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  dateToSend?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  deliveryType: Array<NotificationType>;
  expirationDate?: Maybe<Scalars['DateTime']['output']>;
  headline?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  isDraft?: Maybe<Scalars['Boolean']['output']>;
  orgId?: Maybe<Scalars['String']['output']>;
  seenList: Array<Scalars['String']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};
export enum NotificationAudience {
  AllStudents = 'ALL_STUDENTS',
  OrgManagers = 'ORG_MANAGERS',
  OrgOwners = 'ORG_OWNERS',
  PrivateOrgManagers = 'Private_ORG_MANAGERS',
}
export type NotificationFilterInput = {
  audience?: InputMaybe<Array<NotificationAudience>>;
  deliveryType?: InputMaybe<Array<NotificationType>>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  userId?: InputMaybe<Scalars['String']['input']>;
};
export type NotificationInfo = {
  audience: Array<NotificationAudience>;
  body?: InputMaybe<Scalars['String']['input']>;
  dateToSend?: InputMaybe<Scalars['DateTime']['input']>;
  deliveryType: Array<NotificationType>;
  expirationDate?: InputMaybe<Scalars['DateTime']['input']>;
  headline?: InputMaybe<Scalars['String']['input']>;
  isDraft?: InputMaybe<Scalars['Boolean']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  seenList?: InputMaybe<Array<Scalars['String']['input']>>;
  userId?: InputMaybe<Scalars['String']['input']>;
};
export enum NotificationType {
  Email = 'EMAIL',
  InApp = 'IN_APP',
}
export enum OrgStatus {
  OrgActive = 'ORG_ACTIVE',
  OrgArchived = 'ORG_ARCHIVED',
  OrgPending = 'ORG_PENDING',
}
export type Organization = {
  __typename?: 'Organization';
  accentColor: AccentColor;
  address?: Maybe<Array<Scalars['String']['output']>>;
  administrativeArea?: Maybe<Scalars['String']['output']>;
  alternateContact?: Maybe<Scalars['String']['output']>;
  categories?: Maybe<Array<Category>>;
  contactPerson?: Maybe<Scalars['JSON']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  createdAt: Scalars['DateTime']['output'];
  domains?: Maybe<Array<Scalars['String']['output']>>;
  groups?: Maybe<Array<Scalars['String']['output']>>;
  helpDesk?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  locality?: Maybe<Scalars['String']['output']>;
  location?: Maybe<Location>;
  logo?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  notes?: Maybe<Scalars['String']['output']>;
  permission: PermissionTier;
  portalText?: Maybe<Scalars['String']['output']>;
  postalCode?: Maybe<Scalars['String']['output']>;
  primaryColor: PrimaryColor;
  resource?: Maybe<Array<Resource>>;
  resourceOrderKey?: Maybe<Scalars['JSON']['output']>;
  size?: Maybe<Size>;
  slug?: Maybe<Scalars['String']['output']>;
  status: OrgStatus;
  types?: Maybe<Array<Type>>;
  userOrganization?: Maybe<Array<UserOrganization>>;
  website?: Maybe<Scalars['String']['output']>;
};
export type OrganizationFilter = {
  country?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  permission?: InputMaybe<Array<PermissionTier>>;
  size?: InputMaybe<Array<Size>>;
  status?: InputMaybe<Array<OrgStatus>>;
  website?: InputMaybe<Scalars['String']['input']>;
};
export type OrganizationOfStudent = {
  __typename?: 'OrganizationOfStudent';
  createdAt: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  isJoined: Scalars['Boolean']['output'];
  name: Scalars['String']['output'];
};
export type PaginatedOrgList = {
  __typename?: 'PaginatedOrgList';
  allOrgs?: Maybe<Array<Organization>>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  paginatedOrgList?: Maybe<Array<Organization>>;
  pendingOrgs?: Maybe<Array<Organization>>;
  previousPage?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  totalActive?: Maybe<Scalars['Int']['output']>;
  totalArchived?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};
export type PaginatedUserList = {
  __typename?: 'PaginatedUserList';
  allUsers?: Maybe<Array<Maybe<User>>>;
  nextPage?: Maybe<Scalars['Int']['output']>;
  paginatedUserList?: Maybe<Array<Maybe<User>>>;
  previousPage?: Maybe<Scalars['Int']['output']>;
  total?: Maybe<Scalars['Int']['output']>;
  totalPages?: Maybe<Scalars['Int']['output']>;
};
export type PaginationInput = {
  page?: InputMaybe<Scalars['Int']['input']>;
  perPage?: InputMaybe<Scalars['Int']['input']>;
};
export enum PermissionTier {
  FirstTier = 'FIRST_TIER',
  FourthTier = 'FOURTH_TIER',
  SecondTier = 'SECOND_TIER',
  ThirdTier = 'THIRD_TIER',
}
export type PositiveEffectCount = {
  __typename?: 'PositiveEffectCount';
  ACCOMPLISHED: Scalars['Int']['output'];
  FEEL: Scalars['Int']['output'];
  FUN: Scalars['Int']['output'];
  MEET: Scalars['Int']['output'];
  RELAX: Scalars['Int']['output'];
  SOCIALIZE: Scalars['Int']['output'];
  TASTE: Scalars['Int']['output'];
};
export type PrePostAnswerCount = {
  __typename?: 'PrePostAnswerCount';
  post?: Maybe<Scalars['Int']['output']>;
  pre?: Maybe<Scalars['Int']['output']>;
};
export type PrePostBystanderCount = {
  __typename?: 'PrePostBystanderCount';
  CALL: PrePostAnswerCount;
  OFFER: PrePostAnswerCount;
  RECRUIT: PrePostAnswerCount;
  REMOVE: PrePostAnswerCount;
  WAIT: PrePostAnswerCount;
};
export type PrePostChallengedCount = {
  __typename?: 'PrePostChallengedCount';
  NO: PrePostAnswerCount;
  UNSURE: PrePostAnswerCount;
  YES: PrePostAnswerCount;
};
export type PrePostEnjoyedCount = {
  __typename?: 'PrePostEnjoyedCount';
  NO: PrePostAnswerCount;
  UNSURE: PrePostAnswerCount;
  YES: PrePostAnswerCount;
};
export type PrePostEquippedCount = {
  __typename?: 'PrePostEquippedCount';
  NO: PrePostAnswerCount;
  UNSURE: PrePostAnswerCount;
  YES: PrePostAnswerCount;
};
export type PrePostHarmfulCount = {
  __typename?: 'PrePostHarmfulCount';
  F2M3: PrePostAnswerCount;
  F4M4: PrePostAnswerCount;
  F4M5: PrePostAnswerCount;
  F5M6: PrePostAnswerCount;
};
export type PrePostMetrics = {
  __typename?: 'PrePostMetrics';
  bystanderCount: PrePostBystanderCount;
  challengedCount: PrePostChallengedCount;
  enjoyedCount: PrePostEnjoyedCount;
  equippedCount: PrePostEquippedCount;
  harmfulCount: PrePostHarmfulCount;
  partyCount: PrePostPartyCount;
  protectiveCount: PrePostProtectiveCount;
  questionCountData: PrePostQuestionCountData;
  riskyCount: PrePostRiskyCount;
};
export type PrePostPartyCount = {
  __typename?: 'PrePostPartyCount';
  FOOD: PrePostAnswerCount;
  MANAGE_CROWD: PrePostAnswerCount;
  OPEN_CONTAINERS: PrePostAnswerCount;
  PLAN_AHEAD: PrePostAnswerCount;
};
export type PrePostProtectiveCount = {
  __typename?: 'PrePostProtectiveCount';
  ALTERNATE: PrePostAnswerCount;
  BUDDY: PrePostAnswerCount;
  FOOD: PrePostAnswerCount;
  NO_DRUGS: PrePostAnswerCount;
  OTHER: PrePostAnswerCount;
  PACE: PrePostAnswerCount;
  POSESS: PrePostAnswerCount;
  PREDETERMINE: PrePostAnswerCount;
  SELF_MAKE: PrePostAnswerCount;
  TRACK: PrePostAnswerCount;
};
export type PrePostQuestionCountData = {
  __typename?: 'PrePostQuestionCountData';
  Q01: Scalars['Int']['output'];
  Q31: Scalars['Int']['output'];
  Q32: Scalars['Int']['output'];
  Q33: Scalars['Int']['output'];
  Q34: Scalars['Int']['output'];
  Q35: Scalars['Int']['output'];
  Q36: Scalars['Int']['output'];
  Q37: Scalars['Int']['output'];
  Q38: Scalars['Int']['output'];
};
export type PrePostRiskyCount = {
  __typename?: 'PrePostRiskyCount';
  LESS: PrePostAnswerCount;
  MORE: PrePostAnswerCount;
  NEITHER: PrePostAnswerCount;
  UNSURE: PrePostAnswerCount;
};
export enum Precedence {
  Primary = 'PRIMARY',
  Secondary = 'SECONDARY',
}
export enum PrimaryColor {
  Blue = 'BLUE',
  Fuchsia = 'FUCHSIA',
  Gold = 'GOLD',
  Green = 'GREEN',
  Red = 'RED',
  Slategray = 'SLATEGRAY',
  Steelblue = 'STEELBLUE',
  Teal = 'TEAL',
  Violet = 'VIOLET',
}
export type Query = {
  __typename?: 'Query';
  _empty?: Maybe<Scalars['String']['output']>;
  allOrgManagers: Array<Maybe<User>>;
  allOrgOwners: Array<Maybe<User>>;
  allOrganizations: Array<Maybe<Organization>>;
  allOrgsForSystem?: Maybe<PaginatedOrgList>;
  allRegisteredAndInvitedOrgAdmins: PaginatedUserList;
  allRegisteredAndInvitedSystemAdmins: PaginatedUserList;
  allStudents: Array<Maybe<User>>;
  filteredStudentsForExcel?: Maybe<Array<Maybe<StudentForExcel>>>;
  getInvitedStudentsDetail?: Maybe<Array<InvitationDetail>>;
  getLatestJoinedOrganizationsWithDetails?: Maybe<Array<InvitationDetail>>;
  getLatestVerificationRequest?: Maybe<VerificationRequest>;
  getOrganizationsByUserId?: Maybe<Array<Maybe<Organization>>>;
  getPrimaryResources?: Maybe<Array<Resource>>;
  getStudentMyGroupFilter?: Maybe<Array<Scalars['String']['output']>>;
  invitedByEmail?: Maybe<Array<Maybe<Invite>>>;
  metrics?: Maybe<Metrics>;
  notification?: Maybe<Notification>;
  notificationsByFilter?: Maybe<Array<Notification>>;
  notificationsByStatus?: Maybe<Array<Notification>>;
  organization?: Maybe<Organization>;
  organizationBySlug?: Maybe<Organization>;
  organizations: Array<Maybe<Organization>>;
  organizationsByFilter: Array<Maybe<Organization>>;
  prePostMetrics?: Maybe<PrePostMetrics>;
  registeredAndInvitedAdminsByOrganization: PaginatedUserList;
  registeredAndInvitedStudentsByOrganization: PaginatedUserList;
  registeredAndInvitedUsers: Array<Maybe<User>>;
  resource?: Maybe<Resource>;
  resourcesByOrgId?: Maybe<Array<Resource>>;
  studentOrgsjoinedAndInvited: Array<Maybe<OrganizationOfStudent>>;
  user?: Maybe<User>;
  userByEmail?: Maybe<User>;
  userInvites: Array<Maybe<Invite>>;
  userLessonAnswer?: Maybe<UserLessonAnswer>;
  userLessonAnswersByCompoundId?: Maybe<Array<Maybe<UserLessonAnswer>>>;
  userLessonProgress: Array<Maybe<UserLessonProgress>>;
  userPings?: Maybe<Array<Maybe<UserPing>>>;
  users: Array<Maybe<User>>;
};
export type QueryAllOrgsForSystemArgs = {
  filters?: InputMaybe<SystemOrgFilter>;
  paginationData?: InputMaybe<PaginationInput>;
  searchInput?: InputMaybe<Scalars['String']['input']>;
};
export type QueryAllRegisteredAndInvitedOrgAdminsArgs = {
  filters?: InputMaybe<Array<Scalars['String']['input']>>;
  paginationData?: InputMaybe<PaginationInput>;
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortingInput?: InputMaybe<SortingKey>;
};
export type QueryAllRegisteredAndInvitedSystemAdminsArgs = {
  paginationData?: InputMaybe<PaginationInput>;
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortingInput?: InputMaybe<SortingKey>;
};
export type QueryFilteredStudentsForExcelArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};
export type QueryGetInvitedStudentsDetailArgs = {
  params: InvitedStudentsDetailInput;
};
export type QueryGetLatestJoinedOrganizationsWithDetailsArgs = {
  orgId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};
export type QueryGetLatestVerificationRequestArgs = {
  identifier: Scalars['String']['input'];
};
export type QueryGetOrganizationsByUserIdArgs = {
  userId: Scalars['String']['input'];
};
export type QueryGetStudentMyGroupFilterArgs = {
  orgId: Scalars['String']['input'];
};
export type QueryInvitedByEmailArgs = {
  email: Scalars['String']['input'];
};
export type QueryMetricsArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};
export type QueryNotificationArgs = {
  id: Scalars['Int']['input'];
};
export type QueryNotificationsByFilterArgs = {
  filter: NotificationFilterInput;
};
export type QueryNotificationsByStatusArgs = {
  isDraft: Scalars['Boolean']['input'];
  orgId: Scalars['String']['input'];
};
export type QueryOrganizationArgs = {
  id: Scalars['String']['input'];
};
export type QueryOrganizationBySlugArgs = {
  slug: Scalars['String']['input'];
};
export type QueryOrganizationsArgs = {
  ids: Array<Scalars['String']['input']>;
};
export type QueryOrganizationsByFilterArgs = {
  filter: OrganizationFilter;
};
export type QueryPrePostMetricsArgs = {
  endDate?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
};
export type QueryRegisteredAndInvitedAdminsByOrganizationArgs = {
  orgId: Scalars['String']['input'];
  paginationData?: InputMaybe<PaginationInput>;
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortingInput?: InputMaybe<SortingKey>;
};
export type QueryRegisteredAndInvitedStudentsByOrganizationArgs = {
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId: Scalars['String']['input'];
  paginationData?: InputMaybe<PaginationInput>;
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortingInput?: InputMaybe<SortingKey>;
};
export type QueryRegisteredAndInvitedUsersArgs = {
  ids: Array<Scalars['String']['input']>;
};
export type QueryResourceArgs = {
  id: Scalars['String']['input'];
};
export type QueryResourcesByOrgIdArgs = {
  orgId: Scalars['String']['input'];
};
export type QueryStudentOrgsjoinedAndInvitedArgs = {
  userEmail: Scalars['String']['input'];
};
export type QueryUserArgs = {
  id: Scalars['String']['input'];
};
export type QueryUserByEmailArgs = {
  email: Scalars['String']['input'];
};
export type QueryUserInvitesArgs = {
  emails: Array<Scalars['String']['input']>;
};
export type QueryUserLessonAnswerArgs = {
  progressId: Scalars['String']['input'];
  questionId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};
export type QueryUserLessonAnswersByCompoundIdArgs = {
  progressId: Scalars['String']['input'];
  questionIds: Array<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};
export type QueryUserLessonProgressArgs = {
  userId: Scalars['String']['input'];
};
export type QueryUserPingsArgs = {
  userId: Scalars['String']['input'];
};
export type QueryUsersArgs = {
  ids: Array<Scalars['String']['input']>;
};
export type QuestionAnswerCountData = {
  __typename?: 'QuestionAnswerCountData';
  Q32: Scalars['Int']['output'];
};
export type QuestionCountData = {
  __typename?: 'QuestionCountData';
  Q07: Scalars['Int']['output'];
  Q13: Scalars['Int']['output'];
  Q14: Scalars['Int']['output'];
  Q14a: Scalars['Int']['output'];
  Q14b: Scalars['Int']['output'];
  Q17: Scalars['Int']['output'];
  Q18: Scalars['Int']['output'];
  Q32: Scalars['Int']['output'];
};
export type Resource = {
  __typename?: 'Resource';
  fileType: FileType;
  fileUrl?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  link: Scalars['String']['output'];
  name: Scalars['String']['output'];
  orgId?: Maybe<Scalars['String']['output']>;
  resourceType: ResourceType;
  thumbNail?: Maybe<Scalars['String']['output']>;
};
export type ResourceInput = {
  fileType: FileType;
  fileUrl?: InputMaybe<Scalars['String']['input']>;
  link: Scalars['String']['input'];
  name: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  resourceType: ResourceType;
  thumbNail?: InputMaybe<Scalars['String']['input']>;
};
export enum ResourceType {
  PrimaryResource = 'PRIMARY_RESOURCE',
  SecondaryResource = 'SECONDARY_RESOURCE',
}
export enum Role {
  Developer = 'DEVELOPER',
  OrgManager = 'ORG_MANAGER',
  OrgOwner = 'ORG_OWNER',
  Student = 'STUDENT',
  SystemManager = 'SYSTEM_MANAGER',
  SystemOwner = 'SYSTEM_OWNER',
}
export enum RoleElevation {
  ManageOrgAccount = 'MANAGE_ORG_ACCOUNT',
  ManageOrgAdmins = 'MANAGE_ORG_ADMINS',
  ManageOrgStudents = 'MANAGE_ORG_STUDENTS',
  ViewMetrics = 'VIEW_METRICS',
  ViewOrgAccount = 'VIEW_ORG_ACCOUNT',
  ViewOrgStudents = 'VIEW_ORG_STUDENTS',
}
export type Session = {
  __typename?: 'Session';
  User?: Maybe<User>;
  accessToken?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  sessionToken?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};
export type SessionStudent = {
  __typename?: 'SessionStudent';
  end?: Maybe<Scalars['DateTime']['output']>;
  start?: Maybe<Scalars['DateTime']['output']>;
};
export type Settings = {
  __typename?: 'Settings';
  blockEmailNotifications?: Maybe<Scalars['Boolean']['output']>;
};
export type SettingsInput = {
  blockEmailNotifications?: InputMaybe<Scalars['Boolean']['input']>;
};
export enum Sex {
  Female = 'FEMALE',
  Male = 'MALE',
}
export enum Size {
  Large = 'LARGE',
  Medium = 'MEDIUM',
  Small = 'SMALL',
  Xlarge = 'XLARGE',
  Xsmall = 'XSMALL',
  Xxlarge = 'XXLARGE',
}
export type SoberNegativeCount = {
  __typename?: 'SoberNegativeCount';
  BAD_DECISION: Scalars['Int']['output'];
  DEPRESSED: Scalars['Int']['output'];
  DISAPPOINTED: Scalars['Int']['output'];
  EMBARRASSED: Scalars['Int']['output'];
  GRADES: Scalars['Int']['output'];
  HURT: Scalars['Int']['output'];
  POLICE_TROUBLE: Scalars['Int']['output'];
  PROPERTY: Scalars['Int']['output'];
  SCHOOL_TROUBLE: Scalars['Int']['output'];
};
export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC',
}
export enum SortingKey {
  Ascending = 'ASCENDING',
  Descending = 'DESCENDING',
}
export type StudentForExcel = {
  __typename?: 'StudentForExcel';
  acquisitionChannel?: Maybe<Scalars['String']['output']>;
  courseProgress?: Maybe<Scalars['String']['output']>;
  createdAt?: Maybe<Scalars['String']['output']>;
  deletedAt?: Maybe<Scalars['String']['output']>;
  disciplined?: Maybe<Scalars['String']['output']>;
  dueDate?: Maybe<Scalars['String']['output']>;
  email: Scalars['String']['output'];
  engagedCourseTime?: Maybe<Scalars['String']['output']>;
  exempt?: Maybe<Scalars['String']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Scalars['String']['output']>;
  organizationCategory?: Maybe<Scalars['String']['output']>;
  organizationEnrollment?: Maybe<Scalars['String']['output']>;
  organizationLocation?: Maybe<Scalars['String']['output']>;
  organizationRegion?: Maybe<Scalars['String']['output']>;
  organizationState?: Maybe<Scalars['String']['output']>;
  organizationType?: Maybe<Scalars['String']['output']>;
  passiveCourseTime?: Maybe<Scalars['String']['output']>;
  phone?: Maybe<Scalars['String']['output']>;
  role?: Maybe<Role>;
  roleElevations?: Maybe<Scalars['String']['output']>;
  schoolYear?: Maybe<Scalars['String']['output']>;
  settings?: Maybe<Scalars['String']['output']>;
  sex?: Maybe<Sex>;
  status?: Maybe<UserStatus>;
  userLessonProgress?: Maybe<Scalars['String']['output']>;
  userSessionStartsEnds?: Maybe<Scalars['String']['output']>;
};
export type StudentOfOrganization = {
  __typename?: 'StudentOfOrganization';
  editAccess?: Maybe<Scalars['Boolean']['output']>;
  student?: Maybe<User>;
};
export enum StudentPermission {
  FullPermission = 'FULL_PERMISSION',
  Limited = 'LIMITED',
}
export type StudentsExperienceData = {
  __typename?: 'StudentsExperienceData';
  experienceQ07: ExperienceQ07;
  experienceQ11: StudentsExperienceResult;
};
export type StudentsExperienceInput = {
  endDate?: InputMaybe<Scalars['DateTime']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  questionId: Scalars['String']['input'];
  startDate?: InputMaybe<Scalars['DateTime']['input']>;
};
export type StudentsExperienceResult = {
  __typename?: 'StudentsExperienceResult';
  answer?: Maybe<Scalars['String']['output']>;
  female: Scalars['Int']['output'];
  grandTotal: Scalars['Int']['output'];
  male: Scalars['Int']['output'];
  nonBinary: Scalars['Int']['output'];
  over21: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
  under21: Scalars['Int']['output'];
};
export type SystemOrgFilter = {
  category?: InputMaybe<Array<Category>>;
  permission?: InputMaybe<Array<PermissionTier>>;
  type?: InputMaybe<Array<Type>>;
};
export enum Type {
  FourYear = 'FOUR_YEAR',
  GreekChapter = 'GREEK_CHAPTER',
  GreekNational = 'GREEK_NATIONAL',
  TwoYear = 'TWO_YEAR',
}
export type UpdateInviteInput = {
  disciplined?: InputMaybe<Scalars['Boolean']['input']>;
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  exempt?: InputMaybe<Scalars['Boolean']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  schoolYear?: InputMaybe<Scalars['Int']['input']>;
};
export type UpdateOrganizationInput = {
  accentColor?: InputMaybe<AccentColor>;
  address?: InputMaybe<Array<Scalars['String']['input']>>;
  administrativeArea?: InputMaybe<Scalars['String']['input']>;
  alternateContact?: InputMaybe<Scalars['String']['input']>;
  categories?: InputMaybe<Array<Category>>;
  contactPerson?: InputMaybe<Scalars['JSON']['input']>;
  country?: InputMaybe<Scalars['String']['input']>;
  domains?: InputMaybe<Array<Scalars['String']['input']>>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  helpDesk?: InputMaybe<Scalars['String']['input']>;
  locality?: InputMaybe<Scalars['String']['input']>;
  location?: InputMaybe<Location>;
  logo?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  notes?: InputMaybe<Scalars['String']['input']>;
  permission?: InputMaybe<PermissionTier>;
  portalText?: InputMaybe<Scalars['String']['input']>;
  postalCode?: InputMaybe<Scalars['String']['input']>;
  primaryColor?: InputMaybe<PrimaryColor>;
  size?: InputMaybe<Size>;
  slug?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<OrgStatus>;
  types?: InputMaybe<Array<Type>>;
  website?: InputMaybe<Scalars['String']['input']>;
};
export type UpdateUserInput = {
  acquisition?: InputMaybe<Scalars['String']['input']>;
  deletedAt?: InputMaybe<Scalars['DateTime']['input']>;
  disciplined?: InputMaybe<Scalars['Boolean']['input']>;
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  exempt?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  name?: InputMaybe<Scalars['String']['input']>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  phone?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Role>;
  roleElevations?: InputMaybe<Array<RoleElevation>>;
  schoolYear?: InputMaybe<Scalars['Int']['input']>;
  settings?: InputMaybe<SettingsInput>;
  sex?: InputMaybe<Sex>;
  status?: InputMaybe<UserStatus>;
};
export type User = {
  __typename?: 'User';
  acquisition: Scalars['String']['output'];
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  disciplined?: Maybe<Scalars['Boolean']['output']>;
  dob?: Maybe<Scalars['DateTime']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  editAccess?: Maybe<Scalars['Boolean']['output']>;
  email: Scalars['String']['output'];
  exempt?: Maybe<Scalars['Boolean']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  groups?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  name?: Maybe<Scalars['String']['output']>;
  notification?: Maybe<Array<Maybe<Notification>>>;
  orgId?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  phone?: Maybe<Scalars['String']['output']>;
  role: Role;
  roleElevations?: Maybe<Array<Maybe<RoleElevation>>>;
  schoolYear?: Maybe<Scalars['Int']['output']>;
  sessions?: Maybe<Array<Maybe<Session>>>;
  settings: Settings;
  sex?: Maybe<Sex>;
  status: UserStatus;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  userLessonProgress?: Maybe<Array<Maybe<UserLessonProgress>>>;
  userOrganization?: Maybe<Array<Maybe<UserOrganization>>>;
  userPings?: Maybe<Array<Maybe<UserPing>>>;
};
export type UserFilterInput = {
  disciplined?: InputMaybe<Scalars['Boolean']['input']>;
  dob?: InputMaybe<Scalars['DateTime']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  exempt?: InputMaybe<Scalars['Boolean']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId?: InputMaybe<Scalars['String']['input']>;
  precedence?: InputMaybe<Array<Precedence>>;
  role?: InputMaybe<Array<Role>>;
  schoolYear?: InputMaybe<Scalars['Int']['input']>;
  sex?: InputMaybe<Sex>;
  status?: InputMaybe<Array<UserOrgStatus>>;
  userId?: InputMaybe<Scalars['String']['input']>;
};
export type UserLessonAnswer = {
  __typename?: 'UserLessonAnswer';
  answerPayload?: Maybe<Scalars['JSON']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['String']['output']>;
  progressId?: Maybe<Scalars['String']['output']>;
  questionId?: Maybe<Scalars['String']['output']>;
  timeAnswered?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};
export type UserLessonProgress = {
  __typename?: 'UserLessonProgress';
  completedAt?: Maybe<Scalars['DateTime']['output']>;
  id?: Maybe<Scalars['String']['output']>;
  orgId?: Maybe<Scalars['String']['output']>;
  sectionsComplete?: Maybe<Array<Scalars['String']['output']>>;
  startedAt?: Maybe<Scalars['DateTime']['output']>;
  userId?: Maybe<Scalars['String']['output']>;
};
export enum UserOrgStatus {
  OrgArchived = 'ORG_ARCHIVED',
  OrgJoined = 'ORG_JOINED',
  OrgPending = 'ORG_PENDING',
}
export type UserOrganization = {
  __typename?: 'UserOrganization';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  deletedAt?: Maybe<Scalars['DateTime']['output']>;
  disciplined?: Maybe<Scalars['Boolean']['output']>;
  dueDate?: Maybe<Scalars['DateTime']['output']>;
  exempt?: Maybe<Scalars['Boolean']['output']>;
  groups?: Maybe<Array<Scalars['String']['output']>>;
  id: Scalars['String']['output'];
  isActive?: Maybe<Scalars['Boolean']['output']>;
  isDeleted?: Maybe<Scalars['Boolean']['output']>;
  orgId?: Maybe<Scalars['String']['output']>;
  organization?: Maybe<Organization>;
  precedence?: Maybe<Precedence>;
  schoolYear?: Maybe<Scalars['Int']['output']>;
  seenList?: Maybe<Array<Scalars['String']['output']>>;
  status?: Maybe<UserOrgStatus>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};
export type UserOrganizationInput = {
  disciplined?: InputMaybe<Scalars['Boolean']['input']>;
  dueDate?: InputMaybe<Scalars['DateTime']['input']>;
  exempt?: InputMaybe<Scalars['Boolean']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']>>;
  orgId: Scalars['String']['input'];
  precedence: Precedence;
  schoolYear?: InputMaybe<Scalars['Int']['input']>;
  seenList?: InputMaybe<Array<Scalars['String']['input']>>;
  status: UserOrgStatus;
  userId: Scalars['String']['input'];
};
export type UserPing = {
  __typename?: 'UserPing';
  createdAt: Scalars['DateTime']['output'];
  orgId?: Maybe<Scalars['String']['output']>;
  pageUrl?: Maybe<Scalars['String']['output']>;
  payload?: Maybe<Scalars['JSON']['output']>;
  user?: Maybe<User>;
  userId: Scalars['String']['output'];
};
export type UserPingInfo = {
  orgId?: InputMaybe<Scalars['String']['input']>;
  pageUrl?: InputMaybe<Scalars['String']['input']>;
  payload?: InputMaybe<Scalars['JSON']['input']>;
  userId: Scalars['String']['input'];
};
export enum UserStatus {
  AdminActive = 'ADMIN_ACTIVE',
  AdminArchived = 'ADMIN_ARCHIVED',
  AdminInvited = 'ADMIN_INVITED',
  StudentActive = 'STUDENT_ACTIVE',
  StudentArchived = 'STUDENT_ARCHIVED',
  StudentComplete = 'STUDENT_COMPLETE',
  StudentExempt = 'STUDENT_EXEMPT',
  StudentInvited = 'STUDENT_INVITED',
}
export type VerificationRequest = {
  __typename?: 'VerificationRequest';
  createdAt?: Maybe<Scalars['DateTime']['output']>;
  expires?: Maybe<Scalars['DateTime']['output']>;
  id: Scalars['Int']['output'];
  identifier: Scalars['String']['output'];
  token?: Maybe<Scalars['String']['output']>;
  updatedAt?: Maybe<Scalars['DateTime']['output']>;
};
export type VerificationRequestInput = {
  expires: Scalars['DateTime']['input'];
  identifier: Scalars['String']['input'];
  token: Scalars['String']['input'];
};
export type VirtualBarRevisit = {
  __typename?: 'VirtualBarRevisit';
  revisit: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};
export type VirtualBarStudentRevisit = {
  __typename?: 'VirtualBarStudentRevisit';
  revisit: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};
export type NotificationFragment = {
  __typename?: 'Notification';
  id: number;
  headline?: string | null;
  body?: string | null;
  isDraft?: boolean | null;
  audience: Array<NotificationAudience>;
  seenList: Array<string>;
  orgId?: string | null;
  userId?: string | null;
  deliveryType: Array<NotificationType>;
  dateToSend?: any | null;
  expirationDate?: any | null;
  createdAt?: any | null;
  deletedAt?: any | null;
};
export type InvitationDetailFragment = {
  __typename?: 'InvitationDetail';
  name?: string | null;
  email?: string | null;
  permission?: StudentPermission | null;
};
export type NotificationQueryVariables = Exact<{
  id: Scalars['Int']['input'];
}>;
export type NotificationQuery = {
  __typename?: 'Query';
  notification?: {
    __typename?: 'Notification';
    id: number;
    headline?: string | null;
    body?: string | null;
    isDraft?: boolean | null;
    audience: Array<NotificationAudience>;
    seenList: Array<string>;
    orgId?: string | null;
    userId?: string | null;
    deliveryType: Array<NotificationType>;
    dateToSend?: any | null;
    expirationDate?: any | null;
    createdAt?: any | null;
    deletedAt?: any | null;
  } | null;
};
export type NotificationsByStatusQueryVariables = Exact<{
  isDraft: Scalars['Boolean']['input'];
  orgId: Scalars['String']['input'];
}>;
export type NotificationsByStatusQuery = {
  __typename?: 'Query';
  notificationsByStatus?: Array<{
    __typename?: 'Notification';
    id: number;
    headline?: string | null;
    body?: string | null;
    isDraft?: boolean | null;
    audience: Array<NotificationAudience>;
    seenList: Array<string>;
    orgId?: string | null;
    userId?: string | null;
    deliveryType: Array<NotificationType>;
    dateToSend?: any | null;
    expirationDate?: any | null;
    createdAt?: any | null;
    deletedAt?: any | null;
  }> | null;
};
export type NotificationsByFilterQueryVariables = Exact<{
  filter: NotificationFilterInput;
}>;
export type NotificationsByFilterQuery = {
  __typename?: 'Query';
  notificationsByFilter?: Array<{
    __typename?: 'Notification';
    id: number;
    headline?: string | null;
    body?: string | null;
    isDraft?: boolean | null;
    audience: Array<NotificationAudience>;
    seenList: Array<string>;
    orgId?: string | null;
    userId?: string | null;
    deliveryType: Array<NotificationType>;
    dateToSend?: any | null;
    expirationDate?: any | null;
    createdAt?: any | null;
    deletedAt?: any | null;
  }> | null;
};
export type GetInvitedStudentsDetailQueryVariables = Exact<{
  params: InvitedStudentsDetailInput;
}>;
export type GetInvitedStudentsDetailQuery = {
  __typename?: 'Query';
  getInvitedStudentsDetail?: Array<{
    __typename?: 'InvitationDetail';
    name?: string | null;
    email?: string | null;
    permission?: StudentPermission | null;
  }> | null;
};
export type GetLatestJoinedOrganizationsWithDetailsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
}>;
export type GetLatestJoinedOrganizationsWithDetailsQuery = {
  __typename?: 'Query';
  getLatestJoinedOrganizationsWithDetails?: Array<{
    __typename?: 'InvitationDetail';
    name?: string | null;
    email?: string | null;
    permission?: StudentPermission | null;
  }> | null;
};
export type CreateNotificationMutationVariables = Exact<{
  data: NotificationInfo;
}>;
export type CreateNotificationMutation = {
  __typename?: 'Mutation';
  notification?: {
    __typename?: 'Notification';
    id: number;
    headline?: string | null;
    body?: string | null;
    isDraft?: boolean | null;
    audience: Array<NotificationAudience>;
    seenList: Array<string>;
    orgId?: string | null;
    userId?: string | null;
    deliveryType: Array<NotificationType>;
    dateToSend?: any | null;
    expirationDate?: any | null;
    createdAt?: any | null;
    deletedAt?: any | null;
  } | null;
};
export type UpdateNotificationMutationVariables = Exact<{
  id: Scalars['Int']['input'];
  data: NotificationInfo;
}>;
export type UpdateNotificationMutation = {
  __typename?: 'Mutation';
  notification?: {
    __typename?: 'Notification';
    id: number;
    headline?: string | null;
    body?: string | null;
    isDraft?: boolean | null;
    audience: Array<NotificationAudience>;
    seenList: Array<string>;
    orgId?: string | null;
    userId?: string | null;
    deliveryType: Array<NotificationType>;
    dateToSend?: any | null;
    expirationDate?: any | null;
    createdAt?: any | null;
    deletedAt?: any | null;
  } | null;
};
export type ArchiveNotificationMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;
export type ArchiveNotificationMutation = {
  __typename?: 'Mutation';
  notification?: {
    __typename?: 'Notification';
    id: number;
    headline?: string | null;
    body?: string | null;
    isDraft?: boolean | null;
    audience: Array<NotificationAudience>;
    seenList: Array<string>;
    orgId?: string | null;
    userId?: string | null;
    deliveryType: Array<NotificationType>;
    dateToSend?: any | null;
    expirationDate?: any | null;
    createdAt?: any | null;
    deletedAt?: any | null;
  } | null;
};
export type DeleteNotificationMutationVariables = Exact<{
  id: Scalars['Int']['input'];
}>;
export type DeleteNotificationMutation = {
  __typename?: 'Mutation';
  notification?: {
    __typename?: 'Notification';
    id: number;
    headline?: string | null;
    body?: string | null;
    isDraft?: boolean | null;
    audience: Array<NotificationAudience>;
    seenList: Array<string>;
    orgId?: string | null;
    userId?: string | null;
    deliveryType: Array<NotificationType>;
    dateToSend?: any | null;
    expirationDate?: any | null;
    createdAt?: any | null;
    deletedAt?: any | null;
  } | null;
};
export type ArchiveNotificationsMutationVariables = Exact<{
  ids: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;
export type ArchiveNotificationsMutation = {
  __typename?: 'Mutation';
  notification?: number | null;
};
export type RestoreNotificationsMutationVariables = Exact<{
  ids: Array<Scalars['Int']['input']> | Scalars['Int']['input'];
}>;
export type RestoreNotificationsMutation = {
  __typename?: 'Mutation';
  notification?: number | null;
};
export type OrgFragment = {
  __typename?: 'Organization';
  id: string;
  name: string;
  slug?: string | null;
  status: OrgStatus;
  permission: PermissionTier;
  primaryColor: PrimaryColor;
  accentColor: AccentColor;
  address?: Array<string> | null;
  resourceOrderKey?: any | null;
  postalCode?: string | null;
  locality?: string | null;
  administrativeArea?: string | null;
  country?: string | null;
  website?: string | null;
  contactPerson?: any | null;
  notes?: string | null;
  types?: Array<Type> | null;
  categories?: Array<Category> | null;
  location?: Location | null;
  size?: Size | null;
  logo?: string | null;
  domains?: Array<string> | null;
  portalText?: string | null;
  helpDesk?: string | null;
  alternateContact?: string | null;
  groups?: Array<string> | null;
  createdAt: any;
  resource?: Array<{
    __typename?: 'Resource';
    id: string;
    orgId?: string | null;
    name: string;
    link: string;
    thumbNail?: string | null;
    fileUrl?: string | null;
    fileType: FileType;
    resourceType: ResourceType;
  }> | null;
};
export type PaginatedOrgListFragment = {
  __typename?: 'PaginatedOrgList';
  previousPage?: number | null;
  nextPage?: number | null;
  total?: number | null;
  totalActive?: number | null;
  totalArchived?: number | null;
  totalPages?: number | null;
  pendingOrgs?: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  }> | null;
  allOrgs?: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  }> | null;
  paginatedOrgList?: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  }> | null;
};
export type OrganizationQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;
export type OrganizationQuery = {
  __typename?: 'Query';
  organization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type OrganizationsQueryVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;
export type OrganizationsQuery = {
  __typename?: 'Query';
  organizations: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null>;
};
export type AllOrganizationsQueryVariables = Exact<{
  [key: string]: never;
}>;
export type AllOrganizationsQuery = {
  __typename?: 'Query';
  allOrganizations: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null>;
};
export type AllOrgsForSystemQueryVariables = Exact<{
  searchInput?: InputMaybe<Scalars['String']['input']>;
  paginationData?: InputMaybe<PaginationInput>;
  filters?: InputMaybe<SystemOrgFilter>;
}>;
export type AllOrgsForSystemQuery = {
  __typename?: 'Query';
  allOrgsForSystem?: {
    __typename?: 'PaginatedOrgList';
    previousPage?: number | null;
    nextPage?: number | null;
    total?: number | null;
    totalActive?: number | null;
    totalArchived?: number | null;
    totalPages?: number | null;
    pendingOrgs?: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    }> | null;
    allOrgs?: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    }> | null;
    paginatedOrgList?: Array<{
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    }> | null;
  } | null;
};
export type OrganizationBySlugQueryVariables = Exact<{
  slug: Scalars['String']['input'];
}>;
export type OrganizationBySlugQuery = {
  __typename?: 'Query';
  organizationBySlug?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type OrganizationsByFilterQueryVariables = Exact<{
  filter: OrganizationFilter;
}>;
export type OrganizationsByFilterQuery = {
  __typename?: 'Query';
  organizationsByFilter: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null>;
};
export type CreateOrganizationMutationVariables = Exact<{
  data: CreateOrganizationInput;
}>;
export type CreateOrganizationMutation = {
  __typename?: 'Mutation';
  createOrganization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type UpdateOrganizationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: UpdateOrganizationInput;
}>;
export type UpdateOrganizationMutation = {
  __typename?: 'Mutation';
  updateOrganization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type UpdateResourceOrderMutationVariables = Exact<{
  id: Scalars['String']['input'];
  orderList: Scalars['JSON']['input'];
}>;
export type UpdateResourceOrderMutation = {
  __typename?: 'Mutation';
  updateResourceOrder?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type UpdateOrganizationGroupsMutationVariables = Exact<{
  id: Scalars['String']['input'];
  newGroups: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;
export type UpdateOrganizationGroupsMutation = {
  __typename?: 'Mutation';
  updateOrganizationGroups?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type ArchiveOrganizationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;
export type ArchiveOrganizationMutation = {
  __typename?: 'Mutation';
  archiveOrganization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type ArchiveOrganizationsMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;
export type ArchiveOrganizationsMutation = {
  __typename?: 'Mutation';
  archiveOrganizations: number;
};
export type UnarchiveOrganizationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;
export type UnarchiveOrganizationMutation = {
  __typename?: 'Mutation';
  unarchiveOrganization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type UnarchiveOrganizationsMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;
export type UnarchiveOrganizationsMutation = {
  __typename?: 'Mutation';
  unarchiveOrganizations: number;
};
export type ResourceFragment = {
  __typename?: 'Resource';
  id: string;
  orgId?: string | null;
  name: string;
  link: string;
  thumbNail?: string | null;
  fileUrl?: string | null;
  fileType: FileType;
  resourceType: ResourceType;
};
export type ResourceQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;
export type ResourceQuery = {
  __typename?: 'Query';
  resource?: {
    __typename?: 'Resource';
    id: string;
    orgId?: string | null;
    name: string;
    link: string;
    thumbNail?: string | null;
    fileUrl?: string | null;
    fileType: FileType;
    resourceType: ResourceType;
  } | null;
};
export type ResourcesByOrgIdQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
}>;
export type ResourcesByOrgIdQuery = {
  __typename?: 'Query';
  resourcesByOrgId?: Array<{
    __typename?: 'Resource';
    id: string;
    orgId?: string | null;
    name: string;
    link: string;
    thumbNail?: string | null;
    fileUrl?: string | null;
    fileType: FileType;
    resourceType: ResourceType;
  }> | null;
};
export type GetPrimaryResourcesQueryVariables = Exact<{
  [key: string]: never;
}>;
export type GetPrimaryResourcesQuery = {
  __typename?: 'Query';
  getPrimaryResources?: Array<{
    __typename?: 'Resource';
    id: string;
    orgId?: string | null;
    name: string;
    link: string;
    thumbNail?: string | null;
    fileUrl?: string | null;
    fileType: FileType;
    resourceType: ResourceType;
  }> | null;
};
export type CreateResourceMutationVariables = Exact<{
  name: Scalars['String']['input'];
  data: ResourceInput;
}>;
export type CreateResourceMutation = {
  __typename?: 'Mutation';
  createResource?: {
    __typename?: 'Resource';
    id: string;
    orgId?: string | null;
    name: string;
    link: string;
    thumbNail?: string | null;
    fileUrl?: string | null;
    fileType: FileType;
    resourceType: ResourceType;
  } | null;
};
export type UpdateResourceMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: ResourceInput;
}>;
export type UpdateResourceMutation = {
  __typename?: 'Mutation';
  updateResource?: {
    __typename?: 'Resource';
    id: string;
    orgId?: string | null;
    name: string;
    link: string;
    thumbNail?: string | null;
    fileUrl?: string | null;
    fileType: FileType;
    resourceType: ResourceType;
  } | null;
};
export type DeleteResourceMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;
export type DeleteResourceMutation = {
  __typename?: 'Mutation';
  deleteResource?: {
    __typename?: 'Resource';
    id: string;
    orgId?: string | null;
    name: string;
    link: string;
    thumbNail?: string | null;
    fileUrl?: string | null;
    fileType: FileType;
    resourceType: ResourceType;
  } | null;
};
export type UserLessonAnswerFragment = {
  __typename?: 'UserLessonAnswer';
  id?: string | null;
  progressId?: string | null;
  userId?: string | null;
  questionId?: string | null;
  answerPayload?: any | null;
  timeAnswered?: any | null;
  user?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type StudentsExperienceResultFragment = {
  __typename?: 'StudentsExperienceResult';
  answer?: string | null;
  grandTotal: number;
  total: number;
  under21: number;
  over21: number;
  male: number;
  female: number;
  nonBinary: number;
};
export type MetricsFragment = {
  __typename?: 'Metrics';
  riskFactorsCount: number;
  belowAverageCount: number;
  virtualBarAverageTime: number;
  expressConcernCount: number;
  dashboardData: {
    __typename?: 'DashboardData';
    administratorsInvited: number;
    administratorsRegistered: number;
    averageTimeOnPlatform: number;
    countAllOrganizations: number;
    studentsComplete: number;
    studentsExempt: number;
    studentsInProgress: number;
    studentsRostered: number;
    studentsRegistered: number;
  };
  studentsExperienceData: {
    __typename?: 'StudentsExperienceData';
    experienceQ07: {
      __typename?: 'ExperienceQ07';
      LONG_AGO: {
        __typename?: 'StudentsExperienceResult';
        answer?: string | null;
        grandTotal: number;
        total: number;
        under21: number;
        over21: number;
        male: number;
        female: number;
        nonBinary: number;
      };
      MONTH: {
        __typename?: 'StudentsExperienceResult';
        answer?: string | null;
        grandTotal: number;
        total: number;
        under21: number;
        over21: number;
        male: number;
        female: number;
        nonBinary: number;
      };
      NEVER: {
        __typename?: 'StudentsExperienceResult';
        answer?: string | null;
        grandTotal: number;
        total: number;
        under21: number;
        over21: number;
        male: number;
        female: number;
        nonBinary: number;
      };
      YEAR: {
        __typename?: 'StudentsExperienceResult';
        answer?: string | null;
        grandTotal: number;
        total: number;
        under21: number;
        over21: number;
        male: number;
        female: number;
        nonBinary: number;
      };
    };
    experienceQ11: {
      __typename?: 'StudentsExperienceResult';
      answer?: string | null;
      grandTotal: number;
      total: number;
      under21: number;
      over21: number;
      male: number;
      female: number;
      nonBinary: number;
    };
  };
  questionCountData: {
    __typename?: 'QuestionCountData';
    Q07: number;
    Q13: number;
    Q14: number;
    Q14a: number;
    Q14b: number;
    Q17: number;
    Q18: number;
    Q32: number;
  };
  questionAnswerCountData: {
    __typename?: 'QuestionAnswerCountData';
    Q32: number;
  };
  positiveEffectCount: {
    __typename?: 'PositiveEffectCount';
    ACCOMPLISHED: number;
    FEEL: number;
    FUN: number;
    MEET: number;
    RELAX: number;
    SOCIALIZE: number;
    TASTE: number;
  };
  negativeEffectCount: {
    __typename?: 'NegativeEffectCount';
    BAD_DECISION: number;
    BLACKOUT: number;
    BROWNOUT: number;
    DEPRESSED: number;
    DISAPPOINTED: number;
    DROVE: number;
    EMBARRASSED: number;
    GRADES: number;
    HANGOVER: number;
    HURT: number;
    PASSED_OUT: number;
    POLICE_TROUBLE: number;
    PROPERTY: number;
    SCHOOL_TROUBLE: number;
    SEX: number;
    SOCIAL_MEDIA: number;
    SUICIDE: number;
  };
  virtualBarRevisit: {
    __typename?: 'VirtualBarRevisit';
    revisit: number;
    total: number;
  };
  soberNegativeCount: {
    __typename?: 'SoberNegativeCount';
    PROPERTY: number;
    POLICE_TROUBLE: number;
    HURT: number;
    DISAPPOINTED: number;
    GRADES: number;
    DEPRESSED: number;
    SCHOOL_TROUBLE: number;
    BAD_DECISION: number;
    EMBARRASSED: number;
  };
};
export type PrePostAnswerCountFragment = {
  __typename?: 'PrePostAnswerCount';
  pre?: number | null;
  post?: number | null;
};
export type PrePostMetricsFragment = {
  __typename?: 'PrePostMetrics';
  equippedCount: {
    __typename?: 'PrePostEquippedCount';
    YES: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    NO: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    UNSURE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
  };
  riskyCount: {
    __typename?: 'PrePostRiskyCount';
    MORE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    LESS: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    NEITHER: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    UNSURE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
  };
  protectiveCount: {
    __typename?: 'PrePostProtectiveCount';
    FOOD: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    SELF_MAKE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    POSESS: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    ALTERNATE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    PACE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    PREDETERMINE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    TRACK: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    BUDDY: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    NO_DRUGS: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    OTHER: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
  };
  challengedCount: {
    __typename?: 'PrePostChallengedCount';
    YES: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    NO: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    UNSURE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
  };
  enjoyedCount: {
    __typename?: 'PrePostEnjoyedCount';
    YES: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    NO: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    UNSURE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
  };
  bystanderCount: {
    __typename?: 'PrePostBystanderCount';
    OFFER: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    CALL: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    RECRUIT: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    WAIT: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    REMOVE: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
  };
  harmfulCount: {
    __typename?: 'PrePostHarmfulCount';
    F2M3: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    F4M4: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    F4M5: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    F5M6: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
  };
  partyCount: {
    __typename?: 'PrePostPartyCount';
    FOOD: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    OPEN_CONTAINERS: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    MANAGE_CROWD: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
    PLAN_AHEAD: {
      __typename?: 'PrePostAnswerCount';
      pre?: number | null;
      post?: number | null;
    };
  };
  questionCountData: {
    __typename?: 'PrePostQuestionCountData';
    Q01: number;
    Q31: number;
    Q32: number;
    Q33: number;
    Q34: number;
    Q35: number;
    Q36: number;
    Q37: number;
    Q38: number;
  };
};
export type UserLessonAnswerQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  progressId: Scalars['String']['input'];
  questionId: Scalars['String']['input'];
}>;
export type UserLessonAnswerQuery = {
  __typename?: 'Query';
  userLessonAnswer?: {
    __typename?: 'UserLessonAnswer';
    id?: string | null;
    progressId?: string | null;
    userId?: string | null;
    questionId?: string | null;
    answerPayload?: any | null;
    timeAnswered?: any | null;
    user?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type UserLessonAnswersByCompoundIdQueryVariables = Exact<{
  userId: Scalars['String']['input'];
  progressId: Scalars['String']['input'];
  questionIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;
export type UserLessonAnswersByCompoundIdQuery = {
  __typename?: 'Query';
  userLessonAnswersByCompoundId?: Array<{
    __typename?: 'UserLessonAnswer';
    id?: string | null;
    progressId?: string | null;
    userId?: string | null;
    questionId?: string | null;
    answerPayload?: any | null;
    timeAnswered?: any | null;
    user?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null;
  } | null> | null;
};
export type MetricsQueryVariables = Exact<{
  orgId?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;
export type MetricsQuery = {
  __typename?: 'Query';
  metrics?: {
    __typename?: 'Metrics';
    riskFactorsCount: number;
    belowAverageCount: number;
    virtualBarAverageTime: number;
    expressConcernCount: number;
    dashboardData: {
      __typename?: 'DashboardData';
      administratorsInvited: number;
      administratorsRegistered: number;
      averageTimeOnPlatform: number;
      countAllOrganizations: number;
      studentsComplete: number;
      studentsExempt: number;
      studentsInProgress: number;
      studentsRostered: number;
      studentsRegistered: number;
    };
    studentsExperienceData: {
      __typename?: 'StudentsExperienceData';
      experienceQ07: {
        __typename?: 'ExperienceQ07';
        LONG_AGO: {
          __typename?: 'StudentsExperienceResult';
          answer?: string | null;
          grandTotal: number;
          total: number;
          under21: number;
          over21: number;
          male: number;
          female: number;
          nonBinary: number;
        };
        MONTH: {
          __typename?: 'StudentsExperienceResult';
          answer?: string | null;
          grandTotal: number;
          total: number;
          under21: number;
          over21: number;
          male: number;
          female: number;
          nonBinary: number;
        };
        NEVER: {
          __typename?: 'StudentsExperienceResult';
          answer?: string | null;
          grandTotal: number;
          total: number;
          under21: number;
          over21: number;
          male: number;
          female: number;
          nonBinary: number;
        };
        YEAR: {
          __typename?: 'StudentsExperienceResult';
          answer?: string | null;
          grandTotal: number;
          total: number;
          under21: number;
          over21: number;
          male: number;
          female: number;
          nonBinary: number;
        };
      };
      experienceQ11: {
        __typename?: 'StudentsExperienceResult';
        answer?: string | null;
        grandTotal: number;
        total: number;
        under21: number;
        over21: number;
        male: number;
        female: number;
        nonBinary: number;
      };
    };
    questionCountData: {
      __typename?: 'QuestionCountData';
      Q07: number;
      Q13: number;
      Q14: number;
      Q14a: number;
      Q14b: number;
      Q17: number;
      Q18: number;
      Q32: number;
    };
    questionAnswerCountData: {
      __typename?: 'QuestionAnswerCountData';
      Q32: number;
    };
    positiveEffectCount: {
      __typename?: 'PositiveEffectCount';
      ACCOMPLISHED: number;
      FEEL: number;
      FUN: number;
      MEET: number;
      RELAX: number;
      SOCIALIZE: number;
      TASTE: number;
    };
    negativeEffectCount: {
      __typename?: 'NegativeEffectCount';
      BAD_DECISION: number;
      BLACKOUT: number;
      BROWNOUT: number;
      DEPRESSED: number;
      DISAPPOINTED: number;
      DROVE: number;
      EMBARRASSED: number;
      GRADES: number;
      HANGOVER: number;
      HURT: number;
      PASSED_OUT: number;
      POLICE_TROUBLE: number;
      PROPERTY: number;
      SCHOOL_TROUBLE: number;
      SEX: number;
      SOCIAL_MEDIA: number;
      SUICIDE: number;
    };
    virtualBarRevisit: {
      __typename?: 'VirtualBarRevisit';
      revisit: number;
      total: number;
    };
    soberNegativeCount: {
      __typename?: 'SoberNegativeCount';
      PROPERTY: number;
      POLICE_TROUBLE: number;
      HURT: number;
      DISAPPOINTED: number;
      GRADES: number;
      DEPRESSED: number;
      SCHOOL_TROUBLE: number;
      BAD_DECISION: number;
      EMBARRASSED: number;
    };
  } | null;
};
export type PrePostMetricsQueryVariables = Exact<{
  orgId?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;
export type PrePostMetricsQuery = {
  __typename?: 'Query';
  prePostMetrics?: {
    __typename?: 'PrePostMetrics';
    equippedCount: {
      __typename?: 'PrePostEquippedCount';
      YES: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      NO: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      UNSURE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
    };
    riskyCount: {
      __typename?: 'PrePostRiskyCount';
      MORE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      LESS: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      NEITHER: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      UNSURE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
    };
    protectiveCount: {
      __typename?: 'PrePostProtectiveCount';
      FOOD: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      SELF_MAKE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      POSESS: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      ALTERNATE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      PACE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      PREDETERMINE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      TRACK: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      BUDDY: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      NO_DRUGS: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      OTHER: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
    };
    challengedCount: {
      __typename?: 'PrePostChallengedCount';
      YES: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      NO: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      UNSURE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
    };
    enjoyedCount: {
      __typename?: 'PrePostEnjoyedCount';
      YES: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      NO: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      UNSURE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
    };
    bystanderCount: {
      __typename?: 'PrePostBystanderCount';
      OFFER: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      CALL: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      RECRUIT: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      WAIT: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      REMOVE: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
    };
    harmfulCount: {
      __typename?: 'PrePostHarmfulCount';
      F2M3: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      F4M4: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      F4M5: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      F5M6: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
    };
    partyCount: {
      __typename?: 'PrePostPartyCount';
      FOOD: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      OPEN_CONTAINERS: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      MANAGE_CROWD: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
      PLAN_AHEAD: {
        __typename?: 'PrePostAnswerCount';
        pre?: number | null;
        post?: number | null;
      };
    };
    questionCountData: {
      __typename?: 'PrePostQuestionCountData';
      Q01: number;
      Q31: number;
      Q32: number;
      Q33: number;
      Q34: number;
      Q35: number;
      Q36: number;
      Q37: number;
      Q38: number;
    };
  } | null;
};
export type UpdateUserLessonAnswerMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  progressId: Scalars['String']['input'];
  questionId: Scalars['String']['input'];
  answerPayload: Scalars['JSON']['input'];
}>;
export type UpdateUserLessonAnswerMutation = {
  __typename?: 'Mutation';
  updateUserLessonAnswer: {
    __typename?: 'UserLessonAnswer';
    id?: string | null;
    progressId?: string | null;
    userId?: string | null;
    questionId?: string | null;
    answerPayload?: any | null;
    timeAnswered?: any | null;
    user?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null;
  };
};
export type UserLessonProgressFragment = {
  __typename?: 'UserLessonProgress';
  id?: string | null;
  sectionsComplete?: Array<string> | null;
  userId?: string | null;
  orgId?: string | null;
  startedAt?: any | null;
  completedAt?: any | null;
};
export type UserLessonProgressQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;
export type UserLessonProgressQuery = {
  __typename?: 'Query';
  userLessonProgress: Array<{
    __typename?: 'UserLessonProgress';
    id?: string | null;
    sectionsComplete?: Array<string> | null;
    userId?: string | null;
    orgId?: string | null;
    startedAt?: any | null;
    completedAt?: any | null;
  } | null>;
};
export type CreateUserLessonProgressMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;
export type CreateUserLessonProgressMutation = {
  __typename?: 'Mutation';
  createUserLessonProgress?: {
    __typename?: 'UserLessonProgress';
    id?: string | null;
    sectionsComplete?: Array<string> | null;
    userId?: string | null;
    orgId?: string | null;
    startedAt?: any | null;
    completedAt?: any | null;
  } | null;
};
export type CompleteSectionForUserLessonProgressMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  sectionId: Scalars['String']['input'];
}>;
export type CompleteSectionForUserLessonProgressMutation = {
  __typename?: 'Mutation';
  completeSectionForUserLessonProgress?: {
    __typename?: 'UserLessonProgress';
    id?: string | null;
    sectionsComplete?: Array<string> | null;
    userId?: string | null;
    orgId?: string | null;
    startedAt?: any | null;
    completedAt?: any | null;
  } | null;
};
export type MarkProgressAsCompleteMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;
export type MarkProgressAsCompleteMutation = {
  __typename?: 'Mutation';
  markProgressAsComplete?: {
    __typename?: 'UserLessonProgress';
    id?: string | null;
    sectionsComplete?: Array<string> | null;
    userId?: string | null;
    orgId?: string | null;
    startedAt?: any | null;
    completedAt?: any | null;
  } | null;
};
export type CreateUserLessonProgressesMutationVariables = Exact<{
  userIds: Array<Scalars['String']['input']> | Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;
export type CreateUserLessonProgressesMutation = {
  __typename?: 'Mutation';
  createUserLessonProgresses?: number | null;
};
export type UserOrganizationFragment = {
  __typename?: 'UserOrganization';
  id: string;
  orgId?: string | null;
  userId?: string | null;
  updatedAt?: any | null;
  createdAt?: any | null;
  status?: UserOrgStatus | null;
  precedence?: Precedence | null;
  isActive?: boolean | null;
  isDeleted?: boolean | null;
  schoolYear?: number | null;
  dueDate?: any | null;
  disciplined?: boolean | null;
  exempt?: boolean | null;
  groups?: Array<string> | null;
  seenList?: Array<string> | null;
  deletedAt?: any | null;
};
export type OrganizationOfStudentFragment = {
  __typename?: 'OrganizationOfStudent';
  id: string;
  name: string;
  isJoined: boolean;
  createdAt: any;
};
export type GetOrganizationsByUserIdQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;
export type GetOrganizationsByUserIdQuery = {
  __typename?: 'Query';
  getOrganizationsByUserId?: Array<{
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null> | null;
};
export type StudentOrgsJoinedAndInvitedQueryVariables = Exact<{
  userEmail: Scalars['String']['input'];
}>;
export type StudentOrgsJoinedAndInvitedQuery = {
  __typename?: 'Query';
  studentOrgsjoinedAndInvited: Array<{
    __typename?: 'OrganizationOfStudent';
    id: string;
    name: string;
    isJoined: boolean;
    createdAt: any;
  } | null>;
};
export type UpdateUserOrganizationMutationVariables = Exact<{
  id: Scalars['String']['input'];
  data: UserOrganizationInput;
}>;
export type UpdateUserOrganizationMutation = {
  __typename?: 'Mutation';
  updateUserOrganization?: {
    __typename?: 'UserOrganization';
    id: string;
    orgId?: string | null;
    userId?: string | null;
    updatedAt?: any | null;
    createdAt?: any | null;
    status?: UserOrgStatus | null;
    precedence?: Precedence | null;
    isActive?: boolean | null;
    isDeleted?: boolean | null;
    schoolYear?: number | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    groups?: Array<string> | null;
    seenList?: Array<string> | null;
    deletedAt?: any | null;
  } | null;
};
export type CreateUserOrganizationMutationVariables = Exact<{
  data: UserOrganizationInput;
}>;
export type CreateUserOrganizationMutation = {
  __typename?: 'Mutation';
  userOrganization?: {
    __typename?: 'UserOrganization';
    id: string;
    orgId?: string | null;
    userId?: string | null;
    updatedAt?: any | null;
    createdAt?: any | null;
    status?: UserOrgStatus | null;
    precedence?: Precedence | null;
    isActive?: boolean | null;
    isDeleted?: boolean | null;
    schoolYear?: number | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    groups?: Array<string> | null;
    seenList?: Array<string> | null;
    deletedAt?: any | null;
  } | null;
};
export type DeleteUserOrganizationMutationVariables = Exact<{
  id: Scalars['String']['input'];
}>;
export type DeleteUserOrganizationMutation = {
  __typename?: 'Mutation';
  deleteUserOrganization?: {
    __typename?: 'UserOrganization';
    id: string;
    orgId?: string | null;
    userId?: string | null;
    updatedAt?: any | null;
    createdAt?: any | null;
    status?: UserOrgStatus | null;
    precedence?: Precedence | null;
    isActive?: boolean | null;
    isDeleted?: boolean | null;
    schoolYear?: number | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    groups?: Array<string> | null;
    seenList?: Array<string> | null;
    deletedAt?: any | null;
  } | null;
};
export type DeleteAllOrganizationsOfUserMutationVariables = Exact<{
  userId: Scalars['String']['input'];
}>;
export type DeleteAllOrganizationsOfUserMutation = {
  __typename?: 'Mutation';
  deleteAllOrganizationsOfUser?: number | null;
};
export type JoinSecondaryOrganizationMutationVariables = Exact<{
  userEmail: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  orgId: Scalars['String']['input'];
}>;
export type JoinSecondaryOrganizationMutation = {
  __typename?: 'Mutation';
  joinSecondaryOrganization?: {
    __typename?: 'OrganizationOfStudent';
    id: string;
    name: string;
    isJoined: boolean;
    createdAt: any;
  } | null;
};
export type UserPingFragment = {
  __typename?: 'UserPing';
  userId: string;
  createdAt: any;
  orgId?: string | null;
  pageUrl?: string | null;
  payload?: any | null;
  user?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type UserPingsQueryVariables = Exact<{
  userId: Scalars['String']['input'];
}>;
export type UserPingsQuery = {
  __typename?: 'Query';
  userPings?: Array<{
    __typename?: 'UserPing';
    userId: string;
    createdAt: any;
    orgId?: string | null;
    pageUrl?: string | null;
    payload?: any | null;
    user?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null;
  } | null> | null;
};
export type CreatePingMutationVariables = Exact<{
  data: UserPingInfo;
}>;
export type CreatePingMutation = {
  __typename?: 'Mutation';
  userPing?: {
    __typename?: 'UserPing';
    userId: string;
    createdAt: any;
    orgId?: string | null;
    pageUrl?: string | null;
    payload?: any | null;
    user?: {
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null;
  } | null;
};
export type UserFragment = {
  __typename?: 'User';
  id: string;
  name?: string | null;
  email: string;
  phone?: string | null;
  role: Role;
  roleElevations?: Array<RoleElevation | null> | null;
  status: UserStatus;
  groups?: Array<string> | null;
  schoolYear?: number | null;
  dob?: any | null;
  dueDate?: any | null;
  editAccess?: boolean | null;
  acquisition: string;
  createdAt?: any | null;
  deletedAt?: any | null;
  exempt?: boolean | null;
  disciplined?: boolean | null;
  sex?: Sex | null;
  gender?: string | null;
  orgId?: string | null;
  settings: {
    __typename?: 'Settings';
    blockEmailNotifications?: boolean | null;
  };
  userLessonProgress?: Array<{
    __typename?: 'UserLessonProgress';
    startedAt?: any | null;
    completedAt?: any | null;
    sectionsComplete?: Array<string> | null;
    orgId?: string | null;
    userId?: string | null;
  } | null> | null;
  organization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
  userOrganization?: Array<{
    __typename?: 'UserOrganization';
    id: string;
    orgId?: string | null;
    userId?: string | null;
    createdAt?: any | null;
    updatedAt?: any | null;
    schoolYear?: number | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    groups?: Array<string> | null;
    deletedAt?: any | null;
    organization?: {
      __typename?: 'Organization';
      name: string;
    } | null;
  } | null> | null;
};
export type InviteFragment = {
  __typename?: 'Invite';
  id?: string | null;
  email: string;
  name?: string | null;
  orgId?: string | null;
  role?: Role | null;
  dob?: any | null;
  groups?: Array<string> | null;
  dueDate?: any | null;
  disciplined?: boolean | null;
  exempt?: boolean | null;
  schoolYear?: number | null;
  organization?: {
    __typename?: 'Organization';
    id: string;
    name: string;
    slug?: string | null;
    status: OrgStatus;
    permission: PermissionTier;
    primaryColor: PrimaryColor;
    accentColor: AccentColor;
    address?: Array<string> | null;
    resourceOrderKey?: any | null;
    postalCode?: string | null;
    locality?: string | null;
    administrativeArea?: string | null;
    country?: string | null;
    website?: string | null;
    contactPerson?: any | null;
    notes?: string | null;
    types?: Array<Type> | null;
    categories?: Array<Category> | null;
    location?: Location | null;
    size?: Size | null;
    logo?: string | null;
    domains?: Array<string> | null;
    portalText?: string | null;
    helpDesk?: string | null;
    alternateContact?: string | null;
    groups?: Array<string> | null;
    createdAt: any;
    resource?: Array<{
      __typename?: 'Resource';
      id: string;
      orgId?: string | null;
      name: string;
      link: string;
      thumbNail?: string | null;
      fileUrl?: string | null;
      fileType: FileType;
      resourceType: ResourceType;
    }> | null;
  } | null;
};
export type AccountFragment = {
  __typename?: 'Account';
  id: string;
  providerId: string;
  providerAccountId: string;
  createdAt: any;
};
export type PaginatedUserListFragment = {
  __typename?: 'PaginatedUserList';
  previousPage?: number | null;
  nextPage?: number | null;
  total?: number | null;
  totalPages?: number | null;
  allUsers?: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null> | null;
  paginatedUserList?: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null> | null;
};
export type StudentForExcelFragment = {
  __typename?: 'StudentForExcel';
  name?: string | null;
  email: string;
  phone?: string | null;
  courseProgress?: string | null;
  role?: Role | null;
  roleElevations?: string | null;
  status?: UserStatus | null;
  groups?: string | null;
  schoolYear?: string | null;
  dueDate?: string | null;
  settings?: string | null;
  userLessonProgress?: string | null;
  createdAt?: string | null;
  deletedAt?: string | null;
  exempt?: string | null;
  disciplined?: string | null;
  sex?: Sex | null;
  gender?: string | null;
  acquisitionChannel?: string | null;
  userSessionStartsEnds?: string | null;
  organization?: string | null;
  organizationType?: string | null;
  organizationCategory?: string | null;
  organizationLocation?: string | null;
  organizationRegion?: string | null;
  organizationState?: string | null;
  organizationEnrollment?: string | null;
  engagedCourseTime?: string | null;
  passiveCourseTime?: string | null;
};
export type SessionStudentFragment = {
  __typename?: 'SessionStudent';
  start?: any | null;
  end?: any | null;
};
export type UserQueryVariables = Exact<{
  id: Scalars['String']['input'];
}>;
export type UserQuery = {
  __typename?: 'Query';
  user?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type UsersQueryVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;
export type UsersQuery = {
  __typename?: 'Query';
  users: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null>;
};
export type UserByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;
export type UserByEmailQuery = {
  __typename?: 'Query';
  userByEmail?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type InvitedByEmailQueryVariables = Exact<{
  email: Scalars['String']['input'];
}>;
export type InvitedByEmailQuery = {
  __typename?: 'Query';
  invitedByEmail?: Array<{
    __typename?: 'Invite';
    id?: string | null;
    email: string;
    name?: string | null;
    orgId?: string | null;
    role?: Role | null;
    dob?: any | null;
    groups?: Array<string> | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    schoolYear?: number | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
  } | null> | null;
};
export type UserInvitesQueryVariables = Exact<{
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;
export type UserInvitesQuery = {
  __typename?: 'Query';
  userInvites: Array<{
    __typename?: 'Invite';
    id?: string | null;
    email: string;
    name?: string | null;
    orgId?: string | null;
    role?: Role | null;
    dob?: any | null;
    groups?: Array<string> | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    schoolYear?: number | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
  } | null>;
};
export type AllStudentsQueryVariables = Exact<{
  [key: string]: never;
}>;
export type AllStudentsQuery = {
  __typename?: 'Query';
  allStudents: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null>;
};
export type AllOrgOwnersQueryVariables = Exact<{
  [key: string]: never;
}>;
export type AllOrgOwnersQuery = {
  __typename?: 'Query';
  allOrgOwners: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null>;
};
export type AllOrgManagersQueryVariables = Exact<{
  [key: string]: never;
}>;
export type AllOrgManagersQuery = {
  __typename?: 'Query';
  allOrgManagers: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null>;
};
export type RegisteredAndInvitedStudentsByOrganizationQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortingInput?: InputMaybe<SortingKey>;
  paginationData?: InputMaybe<PaginationInput>;
  groups?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;
export type RegisteredAndInvitedStudentsByOrganizationQuery = {
  __typename?: 'Query';
  registeredAndInvitedStudentsByOrganization: {
    __typename?: 'PaginatedUserList';
    previousPage?: number | null;
    nextPage?: number | null;
    total?: number | null;
    totalPages?: number | null;
    allUsers?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null> | null;
    paginatedUserList?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null> | null;
  };
};
export type RegisteredAndInvitedAdminsByOrganizationQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortingInput?: InputMaybe<SortingKey>;
  paginationData?: InputMaybe<PaginationInput>;
}>;
export type RegisteredAndInvitedAdminsByOrganizationQuery = {
  __typename?: 'Query';
  registeredAndInvitedAdminsByOrganization: {
    __typename?: 'PaginatedUserList';
    previousPage?: number | null;
    nextPage?: number | null;
    total?: number | null;
    totalPages?: number | null;
    allUsers?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null> | null;
    paginatedUserList?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null> | null;
  };
};
export type AllRegisteredAndInvitedOrgAdminsQueryVariables = Exact<{
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortingInput?: InputMaybe<SortingKey>;
  paginationData?: InputMaybe<PaginationInput>;
  filters?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
}>;
export type AllRegisteredAndInvitedOrgAdminsQuery = {
  __typename?: 'Query';
  allRegisteredAndInvitedOrgAdmins: {
    __typename?: 'PaginatedUserList';
    previousPage?: number | null;
    nextPage?: number | null;
    total?: number | null;
    totalPages?: number | null;
    allUsers?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null> | null;
    paginatedUserList?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null> | null;
  };
};
export type AllRegisteredAndInvitedSystemAdminsQueryVariables = Exact<{
  searchInput?: InputMaybe<Scalars['String']['input']>;
  sortingInput?: InputMaybe<SortingKey>;
  paginationData?: InputMaybe<PaginationInput>;
}>;
export type AllRegisteredAndInvitedSystemAdminsQuery = {
  __typename?: 'Query';
  allRegisteredAndInvitedSystemAdmins: {
    __typename?: 'PaginatedUserList';
    previousPage?: number | null;
    nextPage?: number | null;
    total?: number | null;
    totalPages?: number | null;
    allUsers?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null> | null;
    paginatedUserList?: Array<{
      __typename?: 'User';
      id: string;
      name?: string | null;
      email: string;
      phone?: string | null;
      role: Role;
      roleElevations?: Array<RoleElevation | null> | null;
      status: UserStatus;
      groups?: Array<string> | null;
      schoolYear?: number | null;
      dob?: any | null;
      dueDate?: any | null;
      editAccess?: boolean | null;
      acquisition: string;
      createdAt?: any | null;
      deletedAt?: any | null;
      exempt?: boolean | null;
      disciplined?: boolean | null;
      sex?: Sex | null;
      gender?: string | null;
      orgId?: string | null;
      settings: {
        __typename?: 'Settings';
        blockEmailNotifications?: boolean | null;
      };
      userLessonProgress?: Array<{
        __typename?: 'UserLessonProgress';
        startedAt?: any | null;
        completedAt?: any | null;
        sectionsComplete?: Array<string> | null;
        orgId?: string | null;
        userId?: string | null;
      } | null> | null;
      organization?: {
        __typename?: 'Organization';
        id: string;
        name: string;
        slug?: string | null;
        status: OrgStatus;
        permission: PermissionTier;
        primaryColor: PrimaryColor;
        accentColor: AccentColor;
        address?: Array<string> | null;
        resourceOrderKey?: any | null;
        postalCode?: string | null;
        locality?: string | null;
        administrativeArea?: string | null;
        country?: string | null;
        website?: string | null;
        contactPerson?: any | null;
        notes?: string | null;
        types?: Array<Type> | null;
        categories?: Array<Category> | null;
        location?: Location | null;
        size?: Size | null;
        logo?: string | null;
        domains?: Array<string> | null;
        portalText?: string | null;
        helpDesk?: string | null;
        alternateContact?: string | null;
        groups?: Array<string> | null;
        createdAt: any;
        resource?: Array<{
          __typename?: 'Resource';
          id: string;
          orgId?: string | null;
          name: string;
          link: string;
          thumbNail?: string | null;
          fileUrl?: string | null;
          fileType: FileType;
          resourceType: ResourceType;
        }> | null;
      } | null;
      userOrganization?: Array<{
        __typename?: 'UserOrganization';
        id: string;
        orgId?: string | null;
        userId?: string | null;
        createdAt?: any | null;
        updatedAt?: any | null;
        schoolYear?: number | null;
        dueDate?: any | null;
        disciplined?: boolean | null;
        exempt?: boolean | null;
        groups?: Array<string> | null;
        deletedAt?: any | null;
        organization?: {
          __typename?: 'Organization';
          name: string;
        } | null;
      } | null> | null;
    } | null> | null;
  };
};
export type RegisteredAndInvitedUsersQueryVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;
export type RegisteredAndInvitedUsersQuery = {
  __typename?: 'Query';
  registeredAndInvitedUsers: Array<{
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null>;
};
export type FilteredStudentsForExcelQueryVariables = Exact<{
  orgId?: InputMaybe<Scalars['String']['input']>;
  groups?: InputMaybe<Array<Scalars['String']['input']> | Scalars['String']['input']>;
  startDate?: InputMaybe<Scalars['String']['input']>;
  endDate?: InputMaybe<Scalars['String']['input']>;
}>;
export type FilteredStudentsForExcelQuery = {
  __typename?: 'Query';
  filteredStudentsForExcel?: Array<{
    __typename?: 'StudentForExcel';
    name?: string | null;
    email: string;
    phone?: string | null;
    courseProgress?: string | null;
    role?: Role | null;
    roleElevations?: string | null;
    status?: UserStatus | null;
    groups?: string | null;
    schoolYear?: string | null;
    dueDate?: string | null;
    settings?: string | null;
    userLessonProgress?: string | null;
    createdAt?: string | null;
    deletedAt?: string | null;
    exempt?: string | null;
    disciplined?: string | null;
    sex?: Sex | null;
    gender?: string | null;
    acquisitionChannel?: string | null;
    userSessionStartsEnds?: string | null;
    organization?: string | null;
    organizationType?: string | null;
    organizationCategory?: string | null;
    organizationLocation?: string | null;
    organizationRegion?: string | null;
    organizationState?: string | null;
    organizationEnrollment?: string | null;
    engagedCourseTime?: string | null;
    passiveCourseTime?: string | null;
  } | null> | null;
};
export type GetStudentMyGroupFilterQueryVariables = Exact<{
  orgId: Scalars['String']['input'];
}>;
export type GetStudentMyGroupFilterQuery = {
  __typename?: 'Query';
  getStudentMyGroupFilter?: Array<string> | null;
};
export type CreateUserMutationVariables = Exact<{
  data: CreateUserInput;
}>;
export type CreateUserMutation = {
  __typename?: 'Mutation';
  createUser?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type UpdateUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  data: UpdateUserInput;
}>;
export type UpdateUserMutation = {
  __typename?: 'Mutation';
  updateUser?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type UpdateUsersMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  groupPush?: InputMaybe<Scalars['Boolean']['input']>;
  data: UpdateUserInput;
}>;
export type UpdateUsersMutation = {
  __typename?: 'Mutation';
  updateUsers?: number | null;
};
export type UpdateInviteMutationVariables = Exact<{
  email: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  data: UpdateInviteInput;
}>;
export type UpdateInviteMutation = {
  __typename?: 'Mutation';
  updateInvite?: {
    __typename?: 'Invite';
    id?: string | null;
    email: string;
    name?: string | null;
    orgId?: string | null;
    role?: Role | null;
    dob?: any | null;
    groups?: Array<string> | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    schoolYear?: number | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
  } | null;
};
export type UpdateInvitesMutationVariables = Exact<{
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
  groupPush?: InputMaybe<Scalars['Boolean']['input']>;
  data: UpdateInviteInput;
}>;
export type UpdateInvitesMutation = {
  __typename?: 'Mutation';
  updateInvites?: number | null;
};
export type DeleteUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;
export type DeleteUserMutation = {
  __typename?: 'Mutation';
  deleteUser?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type DeleteUsersMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;
export type DeleteUsersMutation = {
  __typename?: 'Mutation';
  deleteUsers?: number | null;
};
export type RestoreUserMutationVariables = Exact<{
  id: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;
export type RestoreUserMutation = {
  __typename?: 'Mutation';
  restoreUser?: {
    __typename?: 'User';
    id: string;
    name?: string | null;
    email: string;
    phone?: string | null;
    role: Role;
    roleElevations?: Array<RoleElevation | null> | null;
    status: UserStatus;
    groups?: Array<string> | null;
    schoolYear?: number | null;
    dob?: any | null;
    dueDate?: any | null;
    editAccess?: boolean | null;
    acquisition: string;
    createdAt?: any | null;
    deletedAt?: any | null;
    exempt?: boolean | null;
    disciplined?: boolean | null;
    sex?: Sex | null;
    gender?: string | null;
    orgId?: string | null;
    settings: {
      __typename?: 'Settings';
      blockEmailNotifications?: boolean | null;
    };
    userLessonProgress?: Array<{
      __typename?: 'UserLessonProgress';
      startedAt?: any | null;
      completedAt?: any | null;
      sectionsComplete?: Array<string> | null;
      orgId?: string | null;
      userId?: string | null;
    } | null> | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
    userOrganization?: Array<{
      __typename?: 'UserOrganization';
      id: string;
      orgId?: string | null;
      userId?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      schoolYear?: number | null;
      dueDate?: any | null;
      disciplined?: boolean | null;
      exempt?: boolean | null;
      groups?: Array<string> | null;
      deletedAt?: any | null;
      organization?: {
        __typename?: 'Organization';
        name: string;
      } | null;
    } | null> | null;
  } | null;
};
export type RestoreUsersMutationVariables = Exact<{
  ids: Array<Scalars['String']['input']> | Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;
export type RestoreUsersMutation = {
  __typename?: 'Mutation';
  restoreUsers?: number | null;
};
export type CreateInviteMutationVariables = Exact<{
  user: InviteInput;
  sendEmail?: InputMaybe<Scalars['Boolean']['input']>;
}>;
export type CreateInviteMutation = {
  __typename?: 'Mutation';
  createInvite?: {
    __typename?: 'Invite';
    id?: string | null;
    email: string;
    name?: string | null;
    orgId?: string | null;
    role?: Role | null;
    dob?: any | null;
    groups?: Array<string> | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    schoolYear?: number | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
  } | null;
};
export type CreateManyInvitesMutationVariables = Exact<{
  users: Array<InviteInput> | InviteInput;
}>;
export type CreateManyInvitesMutation = {
  __typename?: 'Mutation';
  createManyInvites?: Array<{
    __typename?: 'Invite';
    id?: string | null;
    email: string;
    name?: string | null;
    orgId?: string | null;
    role?: Role | null;
    dob?: any | null;
    groups?: Array<string> | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    schoolYear?: number | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
  } | null> | null;
};
export type DeleteInviteMutationVariables = Exact<{
  email: Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;
export type DeleteInviteMutation = {
  __typename?: 'Mutation';
  deleteInvite?: {
    __typename?: 'Invite';
    id?: string | null;
    email: string;
    name?: string | null;
    orgId?: string | null;
    role?: Role | null;
    dob?: any | null;
    groups?: Array<string> | null;
    dueDate?: any | null;
    disciplined?: boolean | null;
    exempt?: boolean | null;
    schoolYear?: number | null;
    organization?: {
      __typename?: 'Organization';
      id: string;
      name: string;
      slug?: string | null;
      status: OrgStatus;
      permission: PermissionTier;
      primaryColor: PrimaryColor;
      accentColor: AccentColor;
      address?: Array<string> | null;
      resourceOrderKey?: any | null;
      postalCode?: string | null;
      locality?: string | null;
      administrativeArea?: string | null;
      country?: string | null;
      website?: string | null;
      contactPerson?: any | null;
      notes?: string | null;
      types?: Array<Type> | null;
      categories?: Array<Category> | null;
      location?: Location | null;
      size?: Size | null;
      logo?: string | null;
      domains?: Array<string> | null;
      portalText?: string | null;
      helpDesk?: string | null;
      alternateContact?: string | null;
      groups?: Array<string> | null;
      createdAt: any;
      resource?: Array<{
        __typename?: 'Resource';
        id: string;
        orgId?: string | null;
        name: string;
        link: string;
        thumbNail?: string | null;
        fileUrl?: string | null;
        fileType: FileType;
        resourceType: ResourceType;
      }> | null;
    } | null;
  } | null;
};
export type DeleteInvitesMutationVariables = Exact<{
  emails: Array<Scalars['String']['input']> | Scalars['String']['input'];
  orgId?: InputMaybe<Scalars['String']['input']>;
}>;
export type DeleteInvitesMutation = {
  __typename?: 'Mutation';
  deleteInvites?: number | null;
};
export type VerificationRequestFragment = {
  __typename?: 'VerificationRequest';
  id: number;
  identifier: string;
  token?: string | null;
  expires?: any | null;
  createdAt?: any | null;
  updatedAt?: any | null;
};
export type GetLatestVerificationRequestQueryVariables = Exact<{
  identifier: Scalars['String']['input'];
}>;
export type GetLatestVerificationRequestQuery = {
  __typename?: 'Query';
  getLatestVerificationRequest?: {
    __typename?: 'VerificationRequest';
    id: number;
    identifier: string;
    token?: string | null;
    expires?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};
export type CreateVerificationRequestMutationVariables = Exact<{
  identifier: Scalars['String']['input'];
  token: Scalars['String']['input'];
  expires: Scalars['DateTime']['input'];
}>;
export type CreateVerificationRequestMutation = {
  __typename?: 'Mutation';
  createVerificationRequest?: {
    __typename?: 'VerificationRequest';
    id: number;
    identifier: string;
    token?: string | null;
    expires?: any | null;
    createdAt?: any | null;
    updatedAt?: any | null;
  } | null;
};
export type WithIndex<TObject> = TObject & Record<string, any>;
export type ResolversObject<TObject> = WithIndex<TObject>;
export type ResolverTypeWrapper<T> = Promise<T> | T;
export type ResolverWithResolve<TResult, TParent, TContext, TArgs> = {
  resolve: ResolverFn<TResult, TParent, TContext, TArgs>;
};
export type Resolver<TResult, TParent = {}, TContext = {}, TArgs = {}> = ResolverFn<TResult, TParent, TContext, TArgs> | ResolverWithResolve<TResult, TParent, TContext, TArgs>;
export type ResolverFn<TResult, TParent, TContext, TArgs> = (parent: TParent, args: TArgs, context: TContext, info: GraphQLResolveInfo) => Promise<TResult> | TResult;
export type SubscriptionSubscribeFn<TResult, TParent, TContext, TArgs> = (parent: TParent, args: TArgs, context: TContext, info: GraphQLResolveInfo) => AsyncIterable<TResult> | Promise<AsyncIterable<TResult>>;
export type SubscriptionResolveFn<TResult, TParent, TContext, TArgs> = (parent: TParent, args: TArgs, context: TContext, info: GraphQLResolveInfo) => TResult | Promise<TResult>;
export interface SubscriptionSubscriberObject<TResult, TKey extends string, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<{ [key in TKey]: TResult }, TParent, TContext, TArgs>;
  resolve?: SubscriptionResolveFn<TResult, { [key in TKey]: TResult }, TContext, TArgs>;
}
export interface SubscriptionResolverObject<TResult, TParent, TContext, TArgs> {
  subscribe: SubscriptionSubscribeFn<any, TParent, TContext, TArgs>;
  resolve: SubscriptionResolveFn<TResult, any, TContext, TArgs>;
}
export type SubscriptionObject<TResult, TKey extends string, TParent, TContext, TArgs> = SubscriptionSubscriberObject<TResult, TKey, TParent, TContext, TArgs> | SubscriptionResolverObject<TResult, TParent, TContext, TArgs>;
export type SubscriptionResolver<TResult, TKey extends string, TParent = {}, TContext = {}, TArgs = {}> = ((...args: any[]) => SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>) | SubscriptionObject<TResult, TKey, TParent, TContext, TArgs>;
export type TypeResolveFn<TTypes, TParent = {}, TContext = {}> = (parent: TParent, context: TContext, info: GraphQLResolveInfo) => Maybe<TTypes> | Promise<Maybe<TTypes>>;
export type IsTypeOfResolverFn<T = {}, TContext = {}> = (obj: T, context: TContext, info: GraphQLResolveInfo) => boolean | Promise<boolean>;
export type NextResolverFn<T> = () => Promise<T>;
export type DirectiveResolverFn<TResult = {}, TParent = {}, TContext = {}, TArgs = {}> = (next: NextResolverFn<TResult>, parent: TParent, args: TArgs, context: TContext, info: GraphQLResolveInfo) => TResult | Promise<TResult>;

/** Mapping between all available schema types and the resolvers types */
export type ResolversTypes = ResolversObject<{
  AccentColor: AccentColor;
  Account: ResolverTypeWrapper<Account>;
  AgeDataFiliter: AgeDataFiliter;
  Boolean: ResolverTypeWrapper<Scalars['Boolean']['output']>;
  Category: Category;
  CreateOrganizationInput: CreateOrganizationInput;
  CreateUserInput: CreateUserInput;
  DOBCOMPARE: Dobcompare;
  DashboardData: ResolverTypeWrapper<DashboardData>;
  DateTime: ResolverTypeWrapper<Scalars['DateTime']['output']>;
  ExperienceQ07: ResolverTypeWrapper<ExperienceQ07>;
  FileType: FileType;
  Float: ResolverTypeWrapper<Scalars['Float']['output']>;
  Gender: Gender;
  Int: ResolverTypeWrapper<Scalars['Int']['output']>;
  InvitationDetail: ResolverTypeWrapper<InvitationDetail>;
  Invite: ResolverTypeWrapper<Invite>;
  InviteInput: InviteInput;
  InvitedStudentsDetailInput: InvitedStudentsDetailInput;
  JSON: ResolverTypeWrapper<Scalars['JSON']['output']>;
  Location: Location;
  Metrics: ResolverTypeWrapper<Metrics>;
  Mutation: ResolverTypeWrapper<{}>;
  NegativeEffectCount: ResolverTypeWrapper<NegativeEffectCount>;
  Notification: ResolverTypeWrapper<Notification>;
  NotificationAudience: NotificationAudience;
  NotificationFilterInput: NotificationFilterInput;
  NotificationInfo: NotificationInfo;
  NotificationType: NotificationType;
  OrgStatus: OrgStatus;
  Organization: ResolverTypeWrapper<Organization>;
  OrganizationFilter: OrganizationFilter;
  OrganizationOfStudent: ResolverTypeWrapper<OrganizationOfStudent>;
  PaginatedOrgList: ResolverTypeWrapper<PaginatedOrgList>;
  PaginatedUserList: ResolverTypeWrapper<PaginatedUserList>;
  PaginationInput: PaginationInput;
  PermissionTier: PermissionTier;
  PositiveEffectCount: ResolverTypeWrapper<PositiveEffectCount>;
  PrePostAnswerCount: ResolverTypeWrapper<PrePostAnswerCount>;
  PrePostBystanderCount: ResolverTypeWrapper<PrePostBystanderCount>;
  PrePostChallengedCount: ResolverTypeWrapper<PrePostChallengedCount>;
  PrePostEnjoyedCount: ResolverTypeWrapper<PrePostEnjoyedCount>;
  PrePostEquippedCount: ResolverTypeWrapper<PrePostEquippedCount>;
  PrePostHarmfulCount: ResolverTypeWrapper<PrePostHarmfulCount>;
  PrePostMetrics: ResolverTypeWrapper<PrePostMetrics>;
  PrePostPartyCount: ResolverTypeWrapper<PrePostPartyCount>;
  PrePostProtectiveCount: ResolverTypeWrapper<PrePostProtectiveCount>;
  PrePostQuestionCountData: ResolverTypeWrapper<PrePostQuestionCountData>;
  PrePostRiskyCount: ResolverTypeWrapper<PrePostRiskyCount>;
  Precedence: Precedence;
  PrimaryColor: PrimaryColor;
  Query: ResolverTypeWrapper<{}>;
  QuestionAnswerCountData: ResolverTypeWrapper<QuestionAnswerCountData>;
  QuestionCountData: ResolverTypeWrapper<QuestionCountData>;
  Resource: ResolverTypeWrapper<Resource>;
  ResourceInput: ResourceInput;
  ResourceType: ResourceType;
  Role: Role;
  RoleElevation: RoleElevation;
  Session: ResolverTypeWrapper<Session>;
  SessionStudent: ResolverTypeWrapper<SessionStudent>;
  Settings: ResolverTypeWrapper<Settings>;
  SettingsInput: SettingsInput;
  Sex: Sex;
  Size: Size;
  SoberNegativeCount: ResolverTypeWrapper<SoberNegativeCount>;
  SortDirection: SortDirection;
  SortingKey: SortingKey;
  String: ResolverTypeWrapper<Scalars['String']['output']>;
  StudentForExcel: ResolverTypeWrapper<StudentForExcel>;
  StudentOfOrganization: ResolverTypeWrapper<StudentOfOrganization>;
  StudentPermission: StudentPermission;
  StudentsExperienceData: ResolverTypeWrapper<StudentsExperienceData>;
  StudentsExperienceInput: StudentsExperienceInput;
  StudentsExperienceResult: ResolverTypeWrapper<StudentsExperienceResult>;
  SystemOrgFilter: SystemOrgFilter;
  Type: Type;
  UpdateInviteInput: UpdateInviteInput;
  UpdateOrganizationInput: UpdateOrganizationInput;
  UpdateUserInput: UpdateUserInput;
  User: ResolverTypeWrapper<User>;
  UserFilterInput: UserFilterInput;
  UserLessonAnswer: ResolverTypeWrapper<UserLessonAnswer>;
  UserLessonProgress: ResolverTypeWrapper<UserLessonProgress>;
  UserOrgStatus: UserOrgStatus;
  UserOrganization: ResolverTypeWrapper<UserOrganization>;
  UserOrganizationInput: UserOrganizationInput;
  UserPing: ResolverTypeWrapper<UserPing>;
  UserPingInfo: UserPingInfo;
  UserStatus: UserStatus;
  VerificationRequest: ResolverTypeWrapper<VerificationRequest>;
  VerificationRequestInput: VerificationRequestInput;
  VirtualBarRevisit: ResolverTypeWrapper<VirtualBarRevisit>;
  VirtualBarStudentRevisit: ResolverTypeWrapper<VirtualBarStudentRevisit>;
}>;

/** Mapping between all available schema types and the resolvers parents */
export type ResolversParentTypes = ResolversObject<{
  Account: Account;
  AgeDataFiliter: AgeDataFiliter;
  Boolean: Scalars['Boolean']['output'];
  CreateOrganizationInput: CreateOrganizationInput;
  CreateUserInput: CreateUserInput;
  DashboardData: DashboardData;
  DateTime: Scalars['DateTime']['output'];
  ExperienceQ07: ExperienceQ07;
  Float: Scalars['Float']['output'];
  Int: Scalars['Int']['output'];
  InvitationDetail: InvitationDetail;
  Invite: Invite;
  InviteInput: InviteInput;
  InvitedStudentsDetailInput: InvitedStudentsDetailInput;
  JSON: Scalars['JSON']['output'];
  Metrics: Metrics;
  Mutation: {};
  NegativeEffectCount: NegativeEffectCount;
  Notification: Notification;
  NotificationFilterInput: NotificationFilterInput;
  NotificationInfo: NotificationInfo;
  Organization: Organization;
  OrganizationFilter: OrganizationFilter;
  OrganizationOfStudent: OrganizationOfStudent;
  PaginatedOrgList: PaginatedOrgList;
  PaginatedUserList: PaginatedUserList;
  PaginationInput: PaginationInput;
  PositiveEffectCount: PositiveEffectCount;
  PrePostAnswerCount: PrePostAnswerCount;
  PrePostBystanderCount: PrePostBystanderCount;
  PrePostChallengedCount: PrePostChallengedCount;
  PrePostEnjoyedCount: PrePostEnjoyedCount;
  PrePostEquippedCount: PrePostEquippedCount;
  PrePostHarmfulCount: PrePostHarmfulCount;
  PrePostMetrics: PrePostMetrics;
  PrePostPartyCount: PrePostPartyCount;
  PrePostProtectiveCount: PrePostProtectiveCount;
  PrePostQuestionCountData: PrePostQuestionCountData;
  PrePostRiskyCount: PrePostRiskyCount;
  Query: {};
  QuestionAnswerCountData: QuestionAnswerCountData;
  QuestionCountData: QuestionCountData;
  Resource: Resource;
  ResourceInput: ResourceInput;
  Session: Session;
  SessionStudent: SessionStudent;
  Settings: Settings;
  SettingsInput: SettingsInput;
  SoberNegativeCount: SoberNegativeCount;
  String: Scalars['String']['output'];
  StudentForExcel: StudentForExcel;
  StudentOfOrganization: StudentOfOrganization;
  StudentsExperienceData: StudentsExperienceData;
  StudentsExperienceInput: StudentsExperienceInput;
  StudentsExperienceResult: StudentsExperienceResult;
  SystemOrgFilter: SystemOrgFilter;
  UpdateInviteInput: UpdateInviteInput;
  UpdateOrganizationInput: UpdateOrganizationInput;
  UpdateUserInput: UpdateUserInput;
  User: User;
  UserFilterInput: UserFilterInput;
  UserLessonAnswer: UserLessonAnswer;
  UserLessonProgress: UserLessonProgress;
  UserOrganization: UserOrganization;
  UserOrganizationInput: UserOrganizationInput;
  UserPing: UserPing;
  UserPingInfo: UserPingInfo;
  VerificationRequest: VerificationRequest;
  VerificationRequestInput: VerificationRequestInput;
  VirtualBarRevisit: VirtualBarRevisit;
  VirtualBarStudentRevisit: VirtualBarStudentRevisit;
}>;
export type AccountResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Account'] = ResolversParentTypes['Account']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerAccountId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  providerId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type DashboardDataResolvers<ContextType = Context, ParentType extends ResolversParentTypes['DashboardData'] = ResolversParentTypes['DashboardData']> = ResolversObject<{
  administratorsInvited?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  administratorsRegistered?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  averageTimeOnPlatform?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  countAllOrganizations?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  studentsComplete?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  studentsExempt?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  studentsInProgress?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  studentsRegistered?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  studentsRostered?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export interface DateTimeScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['DateTime'], any> {
  name: 'DateTime';
}
export type ExperienceQ07Resolvers<ContextType = Context, ParentType extends ResolversParentTypes['ExperienceQ07'] = ResolversParentTypes['ExperienceQ07']> = ResolversObject<{
  LONG_AGO?: Resolver<ResolversTypes['StudentsExperienceResult'], ParentType, ContextType>;
  MONTH?: Resolver<ResolversTypes['StudentsExperienceResult'], ParentType, ContextType>;
  NEVER?: Resolver<ResolversTypes['StudentsExperienceResult'], ParentType, ContextType>;
  YEAR?: Resolver<ResolversTypes['StudentsExperienceResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type InvitationDetailResolvers<ContextType = Context, ParentType extends ResolversParentTypes['InvitationDetail'] = ResolversParentTypes['InvitationDetail']> = ResolversObject<{
  email?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  permission?: Resolver<Maybe<ResolversTypes['StudentPermission']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type InviteResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Invite'] = ResolversParentTypes['Invite']> = ResolversObject<{
  disciplined?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  dob?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exempt?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  groups?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orgId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType>;
  schoolYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export interface JsonScalarConfig extends GraphQLScalarTypeConfig<ResolversTypes['JSON'], any> {
  name: 'JSON';
}
export type MetricsResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Metrics'] = ResolversParentTypes['Metrics']> = ResolversObject<{
  belowAverageCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  dashboardData?: Resolver<ResolversTypes['DashboardData'], ParentType, ContextType>;
  expressConcernCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  negativeEffectCount?: Resolver<ResolversTypes['NegativeEffectCount'], ParentType, ContextType>;
  positiveEffectCount?: Resolver<ResolversTypes['PositiveEffectCount'], ParentType, ContextType>;
  questionAnswerCountData?: Resolver<ResolversTypes['QuestionAnswerCountData'], ParentType, ContextType>;
  questionCountData?: Resolver<ResolversTypes['QuestionCountData'], ParentType, ContextType>;
  riskFactorsCount?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  soberNegativeCount?: Resolver<ResolversTypes['SoberNegativeCount'], ParentType, ContextType>;
  studentsExperienceData?: Resolver<ResolversTypes['StudentsExperienceData'], ParentType, ContextType>;
  virtualBarAverageTime?: Resolver<ResolversTypes['Float'], ParentType, ContextType>;
  virtualBarRevisit?: Resolver<ResolversTypes['VirtualBarRevisit'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type MutationResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Mutation'] = ResolversParentTypes['Mutation']> = ResolversObject<{
  _empty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  archiveNotification?: Resolver<Maybe<ResolversTypes['Notification']>, ParentType, ContextType, RequireFields<MutationArchiveNotificationArgs, 'id'>>;
  archiveNotifications?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MutationArchiveNotificationsArgs, 'ids'>>;
  archiveOrganization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType, RequireFields<MutationArchiveOrganizationArgs, 'id'>>;
  archiveOrganizations?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationArchiveOrganizationsArgs, 'ids'>>;
  completeSectionForUserLessonProgress?: Resolver<Maybe<ResolversTypes['UserLessonProgress']>, ParentType, ContextType, RequireFields<MutationCompleteSectionForUserLessonProgressArgs, 'sectionId' | 'userId'>>;
  createInvite?: Resolver<Maybe<ResolversTypes['Invite']>, ParentType, ContextType, RequireFields<MutationCreateInviteArgs, 'user'>>;
  createManyInvites?: Resolver<Maybe<Array<Maybe<ResolversTypes['Invite']>>>, ParentType, ContextType, RequireFields<MutationCreateManyInvitesArgs, 'users'>>;
  createNotification?: Resolver<Maybe<ResolversTypes['Notification']>, ParentType, ContextType, RequireFields<MutationCreateNotificationArgs, 'data'>>;
  createOrganization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType, RequireFields<MutationCreateOrganizationArgs, 'data'>>;
  createPing?: Resolver<Maybe<ResolversTypes['UserPing']>, ParentType, ContextType, RequireFields<MutationCreatePingArgs, 'data'>>;
  createResource?: Resolver<Maybe<ResolversTypes['Resource']>, ParentType, ContextType, RequireFields<MutationCreateResourceArgs, 'data' | 'name'>>;
  createUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationCreateUserArgs, 'data'>>;
  createUserLessonProgress?: Resolver<Maybe<ResolversTypes['UserLessonProgress']>, ParentType, ContextType, RequireFields<MutationCreateUserLessonProgressArgs, 'userId'>>;
  createUserLessonProgresses?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MutationCreateUserLessonProgressesArgs, 'userIds'>>;
  createUserOrganization?: Resolver<Maybe<ResolversTypes['UserOrganization']>, ParentType, ContextType, RequireFields<MutationCreateUserOrganizationArgs, 'data'>>;
  createVerificationRequest?: Resolver<Maybe<ResolversTypes['VerificationRequest']>, ParentType, ContextType, RequireFields<MutationCreateVerificationRequestArgs, 'expires' | 'identifier' | 'token'>>;
  deleteAllOrganizationsOfUser?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MutationDeleteAllOrganizationsOfUserArgs, 'userId'>>;
  deleteInvite?: Resolver<Maybe<ResolversTypes['Invite']>, ParentType, ContextType, RequireFields<MutationDeleteInviteArgs, 'email'>>;
  deleteInvites?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MutationDeleteInvitesArgs, 'emails'>>;
  deleteNotification?: Resolver<Maybe<ResolversTypes['Notification']>, ParentType, ContextType, RequireFields<MutationDeleteNotificationArgs, 'id'>>;
  deleteResource?: Resolver<Maybe<ResolversTypes['Resource']>, ParentType, ContextType, RequireFields<MutationDeleteResourceArgs, 'id'>>;
  deleteUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationDeleteUserArgs, 'id'>>;
  deleteUserOrganization?: Resolver<Maybe<ResolversTypes['UserOrganization']>, ParentType, ContextType, RequireFields<MutationDeleteUserOrganizationArgs, 'id'>>;
  deleteUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MutationDeleteUsersArgs, 'ids'>>;
  joinSecondaryOrganization?: Resolver<Maybe<ResolversTypes['OrganizationOfStudent']>, ParentType, ContextType, RequireFields<MutationJoinSecondaryOrganizationArgs, 'orgId' | 'userEmail' | 'userId'>>;
  markProgressAsComplete?: Resolver<Maybe<ResolversTypes['UserLessonProgress']>, ParentType, ContextType, RequireFields<MutationMarkProgressAsCompleteArgs, 'userId'>>;
  restoreNotifications?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MutationRestoreNotificationsArgs, 'ids'>>;
  restoreUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationRestoreUserArgs, 'id'>>;
  restoreUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MutationRestoreUsersArgs, 'ids'>>;
  unarchiveOrganization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType, RequireFields<MutationUnarchiveOrganizationArgs, 'id'>>;
  unarchiveOrganizations?: Resolver<ResolversTypes['Int'], ParentType, ContextType, RequireFields<MutationUnarchiveOrganizationsArgs, 'ids'>>;
  updateInvite?: Resolver<Maybe<ResolversTypes['Invite']>, ParentType, ContextType, RequireFields<MutationUpdateInviteArgs, 'data' | 'email'>>;
  updateInvites?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MutationUpdateInvitesArgs, 'data' | 'emails'>>;
  updateNotification?: Resolver<Maybe<ResolversTypes['Notification']>, ParentType, ContextType, RequireFields<MutationUpdateNotificationArgs, 'data' | 'id'>>;
  updateOrganization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType, RequireFields<MutationUpdateOrganizationArgs, 'data' | 'id'>>;
  updateOrganizationGroups?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType, RequireFields<MutationUpdateOrganizationGroupsArgs, 'id' | 'newGroups'>>;
  updateResource?: Resolver<Maybe<ResolversTypes['Resource']>, ParentType, ContextType, RequireFields<MutationUpdateResourceArgs, 'data' | 'id'>>;
  updateResourceOrder?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType, RequireFields<MutationUpdateResourceOrderArgs, 'id' | 'orderList'>>;
  updateUser?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<MutationUpdateUserArgs, 'data' | 'id'>>;
  updateUserLessonAnswer?: Resolver<ResolversTypes['UserLessonAnswer'], ParentType, ContextType, RequireFields<MutationUpdateUserLessonAnswerArgs, 'answerPayload' | 'progressId' | 'questionId' | 'userId'>>;
  updateUserOrganization?: Resolver<Maybe<ResolversTypes['UserOrganization']>, ParentType, ContextType, RequireFields<MutationUpdateUserOrganizationArgs, 'data' | 'id'>>;
  updateUsers?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType, RequireFields<MutationUpdateUsersArgs, 'data' | 'ids'>>;
}>;
export type NegativeEffectCountResolvers<ContextType = Context, ParentType extends ResolversParentTypes['NegativeEffectCount'] = ResolversParentTypes['NegativeEffectCount']> = ResolversObject<{
  BAD_DECISION?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  BLACKOUT?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  BROWNOUT?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  DEPRESSED?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  DISAPPOINTED?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  DROVE?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  EMBARRASSED?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  GRADES?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  HANGOVER?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  HURT?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  PASSED_OUT?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  POLICE_TROUBLE?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  PROPERTY?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  SCHOOL_TROUBLE?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  SEX?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  SOCIAL_MEDIA?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  SUICIDE?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type NotificationResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Notification'] = ResolversParentTypes['Notification']> = ResolversObject<{
  audience?: Resolver<Array<ResolversTypes['NotificationAudience']>, ParentType, ContextType>;
  body?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dateToSend?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deliveryType?: Resolver<Array<ResolversTypes['NotificationType']>, ParentType, ContextType>;
  expirationDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  headline?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  isDraft?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  orgId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  seenList?: Resolver<Array<ResolversTypes['String']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type OrganizationResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Organization'] = ResolversParentTypes['Organization']> = ResolversObject<{
  accentColor?: Resolver<ResolversTypes['AccentColor'], ParentType, ContextType>;
  address?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  administrativeArea?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  alternateContact?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  categories?: Resolver<Maybe<Array<ResolversTypes['Category']>>, ParentType, ContextType>;
  contactPerson?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  country?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  domains?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  groups?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  helpDesk?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  locality?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  location?: Resolver<Maybe<ResolversTypes['Location']>, ParentType, ContextType>;
  logo?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  notes?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  permission?: Resolver<ResolversTypes['PermissionTier'], ParentType, ContextType>;
  portalText?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  postalCode?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  primaryColor?: Resolver<ResolversTypes['PrimaryColor'], ParentType, ContextType>;
  resource?: Resolver<Maybe<Array<ResolversTypes['Resource']>>, ParentType, ContextType>;
  resourceOrderKey?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  size?: Resolver<Maybe<ResolversTypes['Size']>, ParentType, ContextType>;
  slug?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['OrgStatus'], ParentType, ContextType>;
  types?: Resolver<Maybe<Array<ResolversTypes['Type']>>, ParentType, ContextType>;
  userOrganization?: Resolver<Maybe<Array<ResolversTypes['UserOrganization']>>, ParentType, ContextType>;
  website?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type OrganizationOfStudentResolvers<ContextType = Context, ParentType extends ResolversParentTypes['OrganizationOfStudent'] = ResolversParentTypes['OrganizationOfStudent']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isJoined?: Resolver<ResolversTypes['Boolean'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type PaginatedOrgListResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PaginatedOrgList'] = ResolversParentTypes['PaginatedOrgList']> = ResolversObject<{
  allOrgs?: Resolver<Maybe<Array<ResolversTypes['Organization']>>, ParentType, ContextType>;
  nextPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paginatedOrgList?: Resolver<Maybe<Array<ResolversTypes['Organization']>>, ParentType, ContextType>;
  pendingOrgs?: Resolver<Maybe<Array<ResolversTypes['Organization']>>, ParentType, ContextType>;
  previousPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalActive?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalArchived?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type PaginatedUserListResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PaginatedUserList'] = ResolversParentTypes['PaginatedUserList']> = ResolversObject<{
  allUsers?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  nextPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  paginatedUserList?: Resolver<Maybe<Array<Maybe<ResolversTypes['User']>>>, ParentType, ContextType>;
  previousPage?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  total?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  totalPages?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type PositiveEffectCountResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PositiveEffectCount'] = ResolversParentTypes['PositiveEffectCount']> = ResolversObject<{
  ACCOMPLISHED?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  FEEL?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  FUN?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  MEET?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  RELAX?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  SOCIALIZE?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  TASTE?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type PrePostAnswerCountResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PrePostAnswerCount'] = ResolversParentTypes['PrePostAnswerCount']> = ResolversObject<{
  post?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  pre?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type PrePostBystanderCountResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PrePostBystanderCount'] = ResolversParentTypes['PrePostBystanderCount']> = ResolversObject<{
  CALL?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  OFFER?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  RECRUIT?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  REMOVE?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  WAIT?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type PrePostChallengedCountResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PrePostChallengedCount'] = ResolversParentTypes['PrePostChallengedCount']> = ResolversObject<{
  NO?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  UNSURE?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  YES?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type PrePostEnjoyedCountResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PrePostEnjoyedCount'] = ResolversParentTypes['PrePostEnjoyedCount']> = ResolversObject<{
  NO?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  UNSURE?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  YES?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type PrePostEquippedCountResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PrePostEquippedCount'] = ResolversParentTypes['PrePostEquippedCount']> = ResolversObject<{
  NO?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  UNSURE?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  YES?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type PrePostHarmfulCountResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PrePostHarmfulCount'] = ResolversParentTypes['PrePostHarmfulCount']> = ResolversObject<{
  F2M3?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  F4M4?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  F4M5?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  F5M6?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type PrePostMetricsResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PrePostMetrics'] = ResolversParentTypes['PrePostMetrics']> = ResolversObject<{
  bystanderCount?: Resolver<ResolversTypes['PrePostBystanderCount'], ParentType, ContextType>;
  challengedCount?: Resolver<ResolversTypes['PrePostChallengedCount'], ParentType, ContextType>;
  enjoyedCount?: Resolver<ResolversTypes['PrePostEnjoyedCount'], ParentType, ContextType>;
  equippedCount?: Resolver<ResolversTypes['PrePostEquippedCount'], ParentType, ContextType>;
  harmfulCount?: Resolver<ResolversTypes['PrePostHarmfulCount'], ParentType, ContextType>;
  partyCount?: Resolver<ResolversTypes['PrePostPartyCount'], ParentType, ContextType>;
  protectiveCount?: Resolver<ResolversTypes['PrePostProtectiveCount'], ParentType, ContextType>;
  questionCountData?: Resolver<ResolversTypes['PrePostQuestionCountData'], ParentType, ContextType>;
  riskyCount?: Resolver<ResolversTypes['PrePostRiskyCount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type PrePostPartyCountResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PrePostPartyCount'] = ResolversParentTypes['PrePostPartyCount']> = ResolversObject<{
  FOOD?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  MANAGE_CROWD?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  OPEN_CONTAINERS?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  PLAN_AHEAD?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type PrePostProtectiveCountResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PrePostProtectiveCount'] = ResolversParentTypes['PrePostProtectiveCount']> = ResolversObject<{
  ALTERNATE?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  BUDDY?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  FOOD?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  NO_DRUGS?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  OTHER?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  PACE?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  POSESS?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  PREDETERMINE?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  SELF_MAKE?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  TRACK?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type PrePostQuestionCountDataResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PrePostQuestionCountData'] = ResolversParentTypes['PrePostQuestionCountData']> = ResolversObject<{
  Q01?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Q31?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Q32?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Q33?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Q34?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Q35?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Q36?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Q37?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Q38?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type PrePostRiskyCountResolvers<ContextType = Context, ParentType extends ResolversParentTypes['PrePostRiskyCount'] = ResolversParentTypes['PrePostRiskyCount']> = ResolversObject<{
  LESS?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  MORE?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  NEITHER?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  UNSURE?: Resolver<ResolversTypes['PrePostAnswerCount'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type QueryResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Query'] = ResolversParentTypes['Query']> = ResolversObject<{
  _empty?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  allOrgManagers?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType>;
  allOrgOwners?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType>;
  allOrganizations?: Resolver<Array<Maybe<ResolversTypes['Organization']>>, ParentType, ContextType>;
  allOrgsForSystem?: Resolver<Maybe<ResolversTypes['PaginatedOrgList']>, ParentType, ContextType, Partial<QueryAllOrgsForSystemArgs>>;
  allRegisteredAndInvitedOrgAdmins?: Resolver<ResolversTypes['PaginatedUserList'], ParentType, ContextType, Partial<QueryAllRegisteredAndInvitedOrgAdminsArgs>>;
  allRegisteredAndInvitedSystemAdmins?: Resolver<ResolversTypes['PaginatedUserList'], ParentType, ContextType, Partial<QueryAllRegisteredAndInvitedSystemAdminsArgs>>;
  allStudents?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType>;
  filteredStudentsForExcel?: Resolver<Maybe<Array<Maybe<ResolversTypes['StudentForExcel']>>>, ParentType, ContextType, Partial<QueryFilteredStudentsForExcelArgs>>;
  getInvitedStudentsDetail?: Resolver<Maybe<Array<ResolversTypes['InvitationDetail']>>, ParentType, ContextType, RequireFields<QueryGetInvitedStudentsDetailArgs, 'params'>>;
  getLatestJoinedOrganizationsWithDetails?: Resolver<Maybe<Array<ResolversTypes['InvitationDetail']>>, ParentType, ContextType, RequireFields<QueryGetLatestJoinedOrganizationsWithDetailsArgs, 'orgId' | 'userId'>>;
  getLatestVerificationRequest?: Resolver<Maybe<ResolversTypes['VerificationRequest']>, ParentType, ContextType, RequireFields<QueryGetLatestVerificationRequestArgs, 'identifier'>>;
  getOrganizationsByUserId?: Resolver<Maybe<Array<Maybe<ResolversTypes['Organization']>>>, ParentType, ContextType, RequireFields<QueryGetOrganizationsByUserIdArgs, 'userId'>>;
  getPrimaryResources?: Resolver<Maybe<Array<ResolversTypes['Resource']>>, ParentType, ContextType>;
  getStudentMyGroupFilter?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType, RequireFields<QueryGetStudentMyGroupFilterArgs, 'orgId'>>;
  invitedByEmail?: Resolver<Maybe<Array<Maybe<ResolversTypes['Invite']>>>, ParentType, ContextType, RequireFields<QueryInvitedByEmailArgs, 'email'>>;
  metrics?: Resolver<Maybe<ResolversTypes['Metrics']>, ParentType, ContextType, Partial<QueryMetricsArgs>>;
  notification?: Resolver<Maybe<ResolversTypes['Notification']>, ParentType, ContextType, RequireFields<QueryNotificationArgs, 'id'>>;
  notificationsByFilter?: Resolver<Maybe<Array<ResolversTypes['Notification']>>, ParentType, ContextType, RequireFields<QueryNotificationsByFilterArgs, 'filter'>>;
  notificationsByStatus?: Resolver<Maybe<Array<ResolversTypes['Notification']>>, ParentType, ContextType, RequireFields<QueryNotificationsByStatusArgs, 'isDraft' | 'orgId'>>;
  organization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType, RequireFields<QueryOrganizationArgs, 'id'>>;
  organizationBySlug?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType, RequireFields<QueryOrganizationBySlugArgs, 'slug'>>;
  organizations?: Resolver<Array<Maybe<ResolversTypes['Organization']>>, ParentType, ContextType, RequireFields<QueryOrganizationsArgs, 'ids'>>;
  organizationsByFilter?: Resolver<Array<Maybe<ResolversTypes['Organization']>>, ParentType, ContextType, RequireFields<QueryOrganizationsByFilterArgs, 'filter'>>;
  prePostMetrics?: Resolver<Maybe<ResolversTypes['PrePostMetrics']>, ParentType, ContextType, Partial<QueryPrePostMetricsArgs>>;
  registeredAndInvitedAdminsByOrganization?: Resolver<ResolversTypes['PaginatedUserList'], ParentType, ContextType, RequireFields<QueryRegisteredAndInvitedAdminsByOrganizationArgs, 'orgId'>>;
  registeredAndInvitedStudentsByOrganization?: Resolver<ResolversTypes['PaginatedUserList'], ParentType, ContextType, RequireFields<QueryRegisteredAndInvitedStudentsByOrganizationArgs, 'orgId'>>;
  registeredAndInvitedUsers?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType, RequireFields<QueryRegisteredAndInvitedUsersArgs, 'ids'>>;
  resource?: Resolver<Maybe<ResolversTypes['Resource']>, ParentType, ContextType, RequireFields<QueryResourceArgs, 'id'>>;
  resourcesByOrgId?: Resolver<Maybe<Array<ResolversTypes['Resource']>>, ParentType, ContextType, RequireFields<QueryResourcesByOrgIdArgs, 'orgId'>>;
  studentOrgsjoinedAndInvited?: Resolver<Array<Maybe<ResolversTypes['OrganizationOfStudent']>>, ParentType, ContextType, RequireFields<QueryStudentOrgsjoinedAndInvitedArgs, 'userEmail'>>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserArgs, 'id'>>;
  userByEmail?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType, RequireFields<QueryUserByEmailArgs, 'email'>>;
  userInvites?: Resolver<Array<Maybe<ResolversTypes['Invite']>>, ParentType, ContextType, RequireFields<QueryUserInvitesArgs, 'emails'>>;
  userLessonAnswer?: Resolver<Maybe<ResolversTypes['UserLessonAnswer']>, ParentType, ContextType, RequireFields<QueryUserLessonAnswerArgs, 'progressId' | 'questionId' | 'userId'>>;
  userLessonAnswersByCompoundId?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserLessonAnswer']>>>, ParentType, ContextType, RequireFields<QueryUserLessonAnswersByCompoundIdArgs, 'progressId' | 'questionIds' | 'userId'>>;
  userLessonProgress?: Resolver<Array<Maybe<ResolversTypes['UserLessonProgress']>>, ParentType, ContextType, RequireFields<QueryUserLessonProgressArgs, 'userId'>>;
  userPings?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserPing']>>>, ParentType, ContextType, RequireFields<QueryUserPingsArgs, 'userId'>>;
  users?: Resolver<Array<Maybe<ResolversTypes['User']>>, ParentType, ContextType, RequireFields<QueryUsersArgs, 'ids'>>;
}>;
export type QuestionAnswerCountDataResolvers<ContextType = Context, ParentType extends ResolversParentTypes['QuestionAnswerCountData'] = ResolversParentTypes['QuestionAnswerCountData']> = ResolversObject<{
  Q32?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type QuestionCountDataResolvers<ContextType = Context, ParentType extends ResolversParentTypes['QuestionCountData'] = ResolversParentTypes['QuestionCountData']> = ResolversObject<{
  Q07?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Q13?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Q14?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Q14a?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Q14b?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Q17?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Q18?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  Q32?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type ResourceResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Resource'] = ResolversParentTypes['Resource']> = ResolversObject<{
  fileType?: Resolver<ResolversTypes['FileType'], ParentType, ContextType>;
  fileUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  link?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  orgId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  resourceType?: Resolver<ResolversTypes['ResourceType'], ParentType, ContextType>;
  thumbNail?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type SessionResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Session'] = ResolversParentTypes['Session']> = ResolversObject<{
  User?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  accessToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expires?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sessionToken?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type SessionStudentResolvers<ContextType = Context, ParentType extends ResolversParentTypes['SessionStudent'] = ResolversParentTypes['SessionStudent']> = ResolversObject<{
  end?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  start?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type SettingsResolvers<ContextType = Context, ParentType extends ResolversParentTypes['Settings'] = ResolversParentTypes['Settings']> = ResolversObject<{
  blockEmailNotifications?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type SoberNegativeCountResolvers<ContextType = Context, ParentType extends ResolversParentTypes['SoberNegativeCount'] = ResolversParentTypes['SoberNegativeCount']> = ResolversObject<{
  BAD_DECISION?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  DEPRESSED?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  DISAPPOINTED?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  EMBARRASSED?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  GRADES?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  HURT?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  POLICE_TROUBLE?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  PROPERTY?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  SCHOOL_TROUBLE?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type StudentForExcelResolvers<ContextType = Context, ParentType extends ResolversParentTypes['StudentForExcel'] = ResolversParentTypes['StudentForExcel']> = ResolversObject<{
  acquisitionChannel?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  courseProgress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  disciplined?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  engagedCourseTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  exempt?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groups?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationCategory?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationEnrollment?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationLocation?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationRegion?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationState?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organizationType?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  passiveCourseTime?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<Maybe<ResolversTypes['Role']>, ParentType, ContextType>;
  roleElevations?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  schoolYear?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  settings?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sex?: Resolver<Maybe<ResolversTypes['Sex']>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['UserStatus']>, ParentType, ContextType>;
  userLessonProgress?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  userSessionStartsEnds?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type StudentOfOrganizationResolvers<ContextType = Context, ParentType extends ResolversParentTypes['StudentOfOrganization'] = ResolversParentTypes['StudentOfOrganization']> = ResolversObject<{
  editAccess?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  student?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type StudentsExperienceDataResolvers<ContextType = Context, ParentType extends ResolversParentTypes['StudentsExperienceData'] = ResolversParentTypes['StudentsExperienceData']> = ResolversObject<{
  experienceQ07?: Resolver<ResolversTypes['ExperienceQ07'], ParentType, ContextType>;
  experienceQ11?: Resolver<ResolversTypes['StudentsExperienceResult'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type StudentsExperienceResultResolvers<ContextType = Context, ParentType extends ResolversParentTypes['StudentsExperienceResult'] = ResolversParentTypes['StudentsExperienceResult']> = ResolversObject<{
  answer?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  female?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  grandTotal?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  male?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  nonBinary?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  over21?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  under21?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type UserResolvers<ContextType = Context, ParentType extends ResolversParentTypes['User'] = ResolversParentTypes['User']> = ResolversObject<{
  acquisition?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  disciplined?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  dob?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  editAccess?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  email?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  exempt?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  gender?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  groups?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  name?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  notification?: Resolver<Maybe<Array<Maybe<ResolversTypes['Notification']>>>, ParentType, ContextType>;
  orgId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType>;
  phone?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  role?: Resolver<ResolversTypes['Role'], ParentType, ContextType>;
  roleElevations?: Resolver<Maybe<Array<Maybe<ResolversTypes['RoleElevation']>>>, ParentType, ContextType>;
  schoolYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  sessions?: Resolver<Maybe<Array<Maybe<ResolversTypes['Session']>>>, ParentType, ContextType>;
  settings?: Resolver<ResolversTypes['Settings'], ParentType, ContextType>;
  sex?: Resolver<Maybe<ResolversTypes['Sex']>, ParentType, ContextType>;
  status?: Resolver<ResolversTypes['UserStatus'], ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userLessonProgress?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserLessonProgress']>>>, ParentType, ContextType>;
  userOrganization?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserOrganization']>>>, ParentType, ContextType>;
  userPings?: Resolver<Maybe<Array<Maybe<ResolversTypes['UserPing']>>>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type UserLessonAnswerResolvers<ContextType = Context, ParentType extends ResolversParentTypes['UserLessonAnswer'] = ResolversParentTypes['UserLessonAnswer']> = ResolversObject<{
  answerPayload?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orgId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  progressId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  questionId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  timeAnswered?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type UserLessonProgressResolvers<ContextType = Context, ParentType extends ResolversParentTypes['UserLessonProgress'] = ResolversParentTypes['UserLessonProgress']> = ResolversObject<{
  completedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  orgId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  sectionsComplete?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  startedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type UserOrganizationResolvers<ContextType = Context, ParentType extends ResolversParentTypes['UserOrganization'] = ResolversParentTypes['UserOrganization']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  deletedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  disciplined?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  dueDate?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  exempt?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  groups?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  isActive?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  isDeleted?: Resolver<Maybe<ResolversTypes['Boolean']>, ParentType, ContextType>;
  orgId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  organization?: Resolver<Maybe<ResolversTypes['Organization']>, ParentType, ContextType>;
  precedence?: Resolver<Maybe<ResolversTypes['Precedence']>, ParentType, ContextType>;
  schoolYear?: Resolver<Maybe<ResolversTypes['Int']>, ParentType, ContextType>;
  seenList?: Resolver<Maybe<Array<ResolversTypes['String']>>, ParentType, ContextType>;
  status?: Resolver<Maybe<ResolversTypes['UserOrgStatus']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  userId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type UserPingResolvers<ContextType = Context, ParentType extends ResolversParentTypes['UserPing'] = ResolversParentTypes['UserPing']> = ResolversObject<{
  createdAt?: Resolver<ResolversTypes['DateTime'], ParentType, ContextType>;
  orgId?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  pageUrl?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  payload?: Resolver<Maybe<ResolversTypes['JSON']>, ParentType, ContextType>;
  user?: Resolver<Maybe<ResolversTypes['User']>, ParentType, ContextType>;
  userId?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type VerificationRequestResolvers<ContextType = Context, ParentType extends ResolversParentTypes['VerificationRequest'] = ResolversParentTypes['VerificationRequest']> = ResolversObject<{
  createdAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  expires?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  id?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  identifier?: Resolver<ResolversTypes['String'], ParentType, ContextType>;
  token?: Resolver<Maybe<ResolversTypes['String']>, ParentType, ContextType>;
  updatedAt?: Resolver<Maybe<ResolversTypes['DateTime']>, ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type VirtualBarRevisitResolvers<ContextType = Context, ParentType extends ResolversParentTypes['VirtualBarRevisit'] = ResolversParentTypes['VirtualBarRevisit']> = ResolversObject<{
  revisit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type VirtualBarStudentRevisitResolvers<ContextType = Context, ParentType extends ResolversParentTypes['VirtualBarStudentRevisit'] = ResolversParentTypes['VirtualBarStudentRevisit']> = ResolversObject<{
  revisit?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  total?: Resolver<ResolversTypes['Int'], ParentType, ContextType>;
  __isTypeOf?: IsTypeOfResolverFn<ParentType, ContextType>;
}>;
export type Resolvers<ContextType = Context> = ResolversObject<{
  Account?: AccountResolvers<ContextType>;
  DashboardData?: DashboardDataResolvers<ContextType>;
  DateTime?: GraphQLScalarType;
  ExperienceQ07?: ExperienceQ07Resolvers<ContextType>;
  InvitationDetail?: InvitationDetailResolvers<ContextType>;
  Invite?: InviteResolvers<ContextType>;
  JSON?: GraphQLScalarType;
  Metrics?: MetricsResolvers<ContextType>;
  Mutation?: MutationResolvers<ContextType>;
  NegativeEffectCount?: NegativeEffectCountResolvers<ContextType>;
  Notification?: NotificationResolvers<ContextType>;
  Organization?: OrganizationResolvers<ContextType>;
  OrganizationOfStudent?: OrganizationOfStudentResolvers<ContextType>;
  PaginatedOrgList?: PaginatedOrgListResolvers<ContextType>;
  PaginatedUserList?: PaginatedUserListResolvers<ContextType>;
  PositiveEffectCount?: PositiveEffectCountResolvers<ContextType>;
  PrePostAnswerCount?: PrePostAnswerCountResolvers<ContextType>;
  PrePostBystanderCount?: PrePostBystanderCountResolvers<ContextType>;
  PrePostChallengedCount?: PrePostChallengedCountResolvers<ContextType>;
  PrePostEnjoyedCount?: PrePostEnjoyedCountResolvers<ContextType>;
  PrePostEquippedCount?: PrePostEquippedCountResolvers<ContextType>;
  PrePostHarmfulCount?: PrePostHarmfulCountResolvers<ContextType>;
  PrePostMetrics?: PrePostMetricsResolvers<ContextType>;
  PrePostPartyCount?: PrePostPartyCountResolvers<ContextType>;
  PrePostProtectiveCount?: PrePostProtectiveCountResolvers<ContextType>;
  PrePostQuestionCountData?: PrePostQuestionCountDataResolvers<ContextType>;
  PrePostRiskyCount?: PrePostRiskyCountResolvers<ContextType>;
  Query?: QueryResolvers<ContextType>;
  QuestionAnswerCountData?: QuestionAnswerCountDataResolvers<ContextType>;
  QuestionCountData?: QuestionCountDataResolvers<ContextType>;
  Resource?: ResourceResolvers<ContextType>;
  Session?: SessionResolvers<ContextType>;
  SessionStudent?: SessionStudentResolvers<ContextType>;
  Settings?: SettingsResolvers<ContextType>;
  SoberNegativeCount?: SoberNegativeCountResolvers<ContextType>;
  StudentForExcel?: StudentForExcelResolvers<ContextType>;
  StudentOfOrganization?: StudentOfOrganizationResolvers<ContextType>;
  StudentsExperienceData?: StudentsExperienceDataResolvers<ContextType>;
  StudentsExperienceResult?: StudentsExperienceResultResolvers<ContextType>;
  User?: UserResolvers<ContextType>;
  UserLessonAnswer?: UserLessonAnswerResolvers<ContextType>;
  UserLessonProgress?: UserLessonProgressResolvers<ContextType>;
  UserOrganization?: UserOrganizationResolvers<ContextType>;
  UserPing?: UserPingResolvers<ContextType>;
  VerificationRequest?: VerificationRequestResolvers<ContextType>;
  VirtualBarRevisit?: VirtualBarRevisitResolvers<ContextType>;
  VirtualBarStudentRevisit?: VirtualBarStudentRevisitResolvers<ContextType>;
}>;